import React, { useState } from "react";
import Layout from '../../components/Layout'
import { post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Breadcrumbs from "../../components/Breadcrumbs";

const QuestionAdd = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [type, setType] = useState('');
    const [options, setOptions] = useState(['']); // Initialize with one empty option
    const [tags, setTags] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [warning, setWarning] = useState('');
    const [description, setDescription] = useState('');
    const [messageQuestion, setMessageQuestion] = useState('');

    const handleAddOption = () => {
        setOptions([...options, '']);
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleRemoveOption = (index) => {
        if (options.length > 1) { // Ensure at least one option remains
            const newOptions = options.filter((_, i) => i !== index);
            setOptions(newOptions);
            // Adjust correctAnswer if needed
            if (correctAnswer === options[index]) {
                setCorrectAnswer(newOptions[0] || '');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const Body = {
                question,
                type,
                questionType: 'site',
                correctAnswer: type === 'select' || type === 'radio' ? correctAnswer : undefined,
                warning,
                description,
                messageQuestion,
                options: type === 'select' || type === 'radio' ? options : undefined,
                tags: tags ? tags.split(',') : undefined,
            };

            const res = await post('api/questions/add', Body);
            if (res.success) {
                toast.success(res.message);
                setIsLoading(false);
                setQuestion('');
                setType('');
                setOptions(['']); // Reset to one empty option
                setTags('');
                setCorrectAnswer('');
                setWarning('');
                setMessageQuestion('');
            } else {
                toast.error(res.error);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('An error occurred. Please try again.');
        }
    };

    return (
        <Layout>
            <div>
                <Toaster position="top-center" reverseOrder={false} />
                <div className="row mb-9 align-items-center">
                    <div className="col-xxl-9">
                        <div className="row">
                            <div className="col-sm-6 mb-8 mb-sm-0">
                                <h2 className="fs-4 mb-0">Add New Question</h2>
                                <Breadcrumbs />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxl-9">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card mb-8 rounded-4">
                                    <div className="card-body p-7">
                                        <form className="form-border-1" onSubmit={handleSubmit}>
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                    Question title
                                                </label>
                                                <input
                                                    type="text"
                                                    name="question"
                                                    placeholder="Type here"
                                                    className="form-control"
                                                    value={question}
                                                    onChange={(e) => setQuestion(e.target.value)}
                                                    id="product_title"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor="description" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                    Description
                                                </label>
                                                <ReactQuill theme="snow" value={description} onChange={setDescription} />
                                            </div>
                                            <div className="mb-8">
                                                <label className="mb-4 fs-13px ls-1 fw-bold text-uppercase" htmlFor="currency">
                                                    Choose Input Type
                                                </label>
                                                <select
                                                    className="form-select"
                                                    name="type"
                                                    value={type}
                                                    id="currency"
                                                    onChange={(e) => setType(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Choose</option>
                                                    <option value="textarea">TextArea</option>
                                                    <option value="select">Select</option>
                                                    <option value="radio">Radio</option>
                                                </select>
                                            </div>
                                            {(type === 'select' || type === 'radio') && (
                                                <div className="mb-8">
                                                    <label htmlFor="options" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                        Question Options:
                                                    </label>
                                                    {options.map((option, index) => (
                                                        <div key={index} className="d-flex mb-2">
                                                            <input
                                                                type="text"
                                                                name={`option_${index}`}
                                                                placeholder={`Option ${index + 1}`}
                                                                className="form-control me-2"
                                                                value={option}
                                                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRemoveOption(index)}
                                                                disabled={index === 0}
                                                                className="btn btn-link"
                                                                style={{ margin: '10px', color: index === 0 ? 'gray' : 'red' }}
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm mt-4"
                                                        onClick={handleAddOption}
                                                    >
                                                        <i className="fas fa-plus"></i> Add
                                                    </button>
                                                </div>
                                            )}
                                            {(type === 'select' || type === 'radio') && (
                                                <div className="mb-8">
                                                    <label htmlFor="correctAnswer" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                        Correct Answer:
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="correctAnswer"
                                                        value={correctAnswer}
                                                        onChange={(e) => setCorrectAnswer(e.target.value)}
                                                        id="correctAnswer"
                                                        required
                                                    >
                                                        <option value="">Select an option</option>
                                                        {options.map((option, index) => (
                                                            <option key={index} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                            <div className="mb-8">
                                                <label htmlFor="warning" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                    Wrong Answer Warning:
                                                </label>
                                                <textarea
                                                    placeholder="Write here.."
                                                    className="form-control"
                                                    name="warning"
                                                    value={warning}
                                                    rows={5}
                                                    onChange={(e) => setWarning(e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor="tags" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                    Question Tags (comma-separated):
                                                </label>
                                                <input
                                                    type="text"
                                                    name="tags"
                                                    placeholder="Type here"
                                                    className="form-control"
                                                    value={tags}
                                                    onChange={(e) => setTags(e.target.value)}
                                                    id="tags"
                                                />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor="messageQuestion" className="mb-4 fs-13px ls-1 fw-bold text-uppercase">
                                                    eBay Message Assessment:
                                                </label>
                                                <textarea
                                                    placeholder="Write eBay message question here"
                                                    className="form-control"
                                                    name="messageQuestion"
                                                    value={messageQuestion}
                                                    rows={5}
                                                    onChange={(e) => setMessageQuestion(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end">
                                                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                    {isLoading && <i className="fa fa-spinner fa-spin"></i>} Publish
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default QuestionAdd;
