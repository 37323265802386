import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import "../custom-style.css";
import Loader from "../../components/Loader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { get, post, del } from "../../services/ApiService";
import { Link, useParams } from "react-router-dom";

const EbayQuestionDemo = () => {
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [order, setOrder] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);


  useEffect(() => {
    fetchQuestions();
  }, [id]);



  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const res = await get(`api/orders/assessment/question/demo/${id}`);
      if (res.success) {
        setQuestions(res.questions);
        setOrder(res.order);
        setLoading(false);
        setNotFound(false);
      } else {
        setQuestions([]);
        setLoading(false);
        setNotFound(true);
      }
    } catch (error) {
      setQuestions([]);
      setLoading(false);
      setNotFound(true);
    }
  };


  return (
    <>
      <div>
        <header className="border-bottom fixed-top bg-white header-section">
          <div className="container">
            <div className="py-3">
              <img
                src="/assets/images/logo.png"
                className="img-fluid"
                width="150px"
                alt=""
              />
            </div>
          </div>
        </header>
        <section className="py-5 bg-light ">
          <div className="container">
            <div className="row justify-content-center main-section">
              {loading && <Loader />}
              {!loading && !isNotFound  && (
                <>
                  <div className="col-lg-10">
                    <Link to={`/orders/${order?._id}`}>
                      <i class="fa fa-arrow-left" aria-hidden="true" style={{fontSize:'30px'}}></i>
                    </Link>
                    <div className="card rounded-3">
                      <div className="card-body">
                        <p className="m-0 p-0">
                        <p className="m-0 p-0 text-capitalize">Dear {order?.buyerInfo?.fullname},</p><br/>
                        <p className="m-0 p-0">
                           Thank you for your order. Before we dispatch your item, we need to confirm a few details to ensure this medication is safe and appropriate for your use. I agree to the following:
                        </p><br/>
                        {questions.map((question,index)=>(
                         <> <p>{index + 1}. {question}</p><br/></>
                        ))}
                        
                        <br/><p className="m-0 p-0">
                            Please reply to this email with “I agree” for the above statements so we can process your order promptly. If there is any question you cannot agree to, kindly reply with as much information as you can, and it will help our pharmacists to approve your order.
                        </p><br/>
                        <p className="m-0 p-0">
                            If you have any questions or need further information, feel free to contact us.
                        </p><br/>
                        <p className="m-0 p-0"> Thank you for your cooperation.</p>
                        <p className="m-0 p-0"> Kind regards,</p>
                            PillPharm<br/>
                            Pharmacist Team

                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {isNotFound && (
                <div class="col-12 text-center mb-5">
                  <div class="error-template">
                    <h1> Oops!</h1>
                    <h2>404 Not Found</h2>
                    <div class="error-details">
                      Sorry, an error has occured, Requested page not found!
                    </div>
                  </div>
                </div>
              )}



            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EbayQuestionDemo;
