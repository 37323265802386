import React from 'react';
import { Modal } from 'antd';

const DeleteModal = ({ visible, onClose, onConfirm, isDelete}) => {
  return (
    <Modal
      open={visible}
      closable={false}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      width={300}
    >
      <div className='col-12 text-center'>
        <h5>
            <span className='text-danger' style={{fontSize:'17px', fontWeight:'200', letterSpacing:'.02rem'}}> Are you sure ?</span> <br/>
            <span  style={{fontSize:'15px',fontWeight:'100'}}>you wish to delete this</span>
        </h5>
        <div className='mt-4'>
            <button className='btn btn-danger btn-sm m-3 rounded-pill' onClick={onClose}>No</button>
            <button className='btn btn-primary btn-sm m-3 rounded-pill' onClick={onConfirm} disabled={isDelete}>Yes</button>
        </div>

      </div>
    </Modal>
  );
};

export default DeleteModal;
