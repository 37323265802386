import React, { useState } from 'react';
import toast from "react-hot-toast";
import axios from 'axios';
import {post } from "../services/ApiService";


const NoteForm = ({ id, setNotes, notes }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isFormSubmit, setIsFormSubmit]= useState(false)

    const handleCreateNote = async (e) => {
        e.preventDefault();
        try {
            setIsFormSubmit(true);
            const Body = {
                category: 'Order',
                orderId: id,
                title,
                description
            };
            const res = await post(`api/notes/add`, Body);

            setIsFormSubmit(false);
            if (res.success) {
                setNotes([res.note, ...notes]);
                toast.success('Note addedd successfully');
                setTitle('');
                setDescription('');
            } else {
                toast.error(`Failed to add note`);
            }
        } catch (error) {
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };



    return (
        <form onSubmit={handleCreateNote}>
            <div className="form-group mb-4">
                <input 
                    className="form-control" 
                    type="text" 
                    name="title" 
                    placeholder="Enter note subject" 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required 
                    style={{background:'white'}}
                />
            </div>
            <div className="form-group mb-4">
                <textarea 
                    className="form-control" 
                    name="description" 
                    placeholder="Write note description.." 
                    rows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required 
                    style={{background:'white'}}

                />
            </div>
            <button className="btn btn-primary btn-sm" type="submit" disabled={isFormSubmit}>
                {isFormSubmit && <i className='fa fa-spinner fa-spin'></i>} Add
            </button>
        </form>
    );
};

export default NoteForm;
