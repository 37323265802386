import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, postFile } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import VariationTable from "./VariationTable";
import Pagination from '../../components/Pagination';
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";

const VariationList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNotFound, setNotFound] = useState(false); 
  const [variations, setVariations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(100);
  const [file, setFile] = useState(null);
  const [filters, setFilters] = useState({
      sku: '',
  });

  useEffect(() => {
    fetchData();
  }, [currentPage, filters]);

  const fetchData = async () => {

    try {

        setIsLoading(true);
        const Body = {
          page: currentPage,
          limit: limit,
          ...filters
        };
        const res = await post(`api/products/variation/list`, Body);
        if (res.success) {
          setIsLoading(false);
          setVariations(res.variations);
          setTotalPages(res.totalPages);
          setNotFound(false)
        } else {
          setVariations([]);
          setTotalPages(1);
          setIsLoading(false);
          setNotFound(true);
        }
    } catch (error) {
        setVariations([]);
        setTotalPages(1);
        setIsLoading(false);
        setNotFound(true);
        console.error(error.message);
    }
  };


const handlePageChange = (page) => {
    setCurrentPage(page);
};

const handleFilterChange = (e) => {
    setFilters({
        ...filters,
        [e.target.name]: e.target.value
    });
};
const handleFileChange = (e) => {
    setFile(e.target.files[0]);
};
const handleUpload =async () => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        setIsSubmit(true);
        const res = await postFile(`api/products/variations/upload`, formData);
        console.log(res)
        if (res.success) {
            toast.success(res.message);
            setIsSubmit(false);
        } else {
            toast.error(res.error);
            setIsSubmit(false);
        }
    } catch (error) {
        setIsSubmit(false);
        console.log(error)
        toast.error('An error occurred. Please try again.');
    }


};


  return (
    <>
    <Layout>
      <Toaster 
          position="top-center"
          reverseOrder={false}
      />
      <div className="row mb-9 align-items-center justify-content-between">
        <div className="col-md-4 mb-8 mb-md-0">
          <h2 className="fs-4 mb-0">Variation List</h2>
          <Breadcrumbs />
        </div>
        <div className="col-md-8 d-flex flex-wrap justify-content-md-end">

        </div>
      </div>
      <div className="card mb-4 rounded-4 p-7">
        <div className="card-header bg-transparent px-0 pt-0 pb-7">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-12 mr-auto mb-md-0 mb-6">
                <input type="text" name="sku" placeholder="search by sku..." value={filters.sku} onChange={handleFilterChange} className="form-control bg-input border-0" />
            </div>
            <div className="col-md-6 col-12 mr-auto mb-md-0 mb-6 text-end">
                <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">Uplaod Excel</button>
            </div>
          </div>
        </div>
        <div className="card-body px-0 pt-7 pb-0">
          {isLoading ? (
            <div className='text-center'>
              <Loader />
            </div>
          ):( 
              <div className="table-responsive">
                <VariationTable variations={variations}  />
              </div>
            
          )}


          {!isLoading && isNotFound && 
            <div className="text-center">
              <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
              <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
            </div>
          }
        </div>
      </div>
      {totalPages && totalPages > 1 && 
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      }

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Upload Excel</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                        <div className="col-12">
                            <input type="file" className="form-control" onChange={handleFileChange}  />
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onClick={handleUpload} disabled={isSubmit}>
                       {isSubmit && <i className="fa fa-spinner fa-spin"></i>} Upload
                    </button>
                </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default VariationList