import React from "react";
import { Link } from "react-router-dom";
import { UkDateFormater } from "../../../services/Helper";

const Table = ({ logs }) => {


  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover  mb-0 table-responsive">
          <thead className="table-light">
          <tr>
                        <th className="align-middle" scope="col" style={{ minWidth: '50px' }}>
                            No:
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '120px' }}>
                            Date
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '80px' }}>
                            Time
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '150px' }}>
                            Staffing Level
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '150px' }}>
                            Dispensed By
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '150px' }}>
                            Checked By
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '200px' }}>
                            Name & Brand of Drug
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '200px' }}>
                            Type of Near Miss
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '200px' }}>
                            Possible Causes
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '200px' }}>
                            Things to Consider
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '200px' }}>
                            Action Taken
                        </th>
                        <th className="align-middle" scope="col" style={{ minWidth: '200px' }}>
                            Discussion
                        </th>
                        <th className="align-middle text-center" scope="col" style={{ minWidth: '100px' }}>
                            
                        </th>
                    </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => (
              
              <tr key={index}>
                
                <td>
                  {index + 1}
                </td>
                <td className="text-body-emphasis">{UkDateFormater(log.date)}</td>
                <td>{log.time}</td>
                <td>{log.staffingLevel}</td>
                <td>{log.despensedBy}</td>
                <td>{log.checkedBy}</td>
                <td>{log.drugName}</td>
                <td>{log.type}</td>
                <td>{log.cause}</td>
                <td>
                <div className="question_description"
                  dangerouslySetInnerHTML={{ __html: log.things }}
                />
                </td>
                <td>                
                  <div className="question_description"
                    dangerouslySetInnerHTML={{ __html: log.action }}
                  />
                </td>
                <td>                
                  <div className="question_description"
                  dangerouslySetInnerHTML={{ __html: log.discussion }}
                /></td>
                <td>
                    <Link to={`/near-miss-logs/edit/${log._id}`} className="text-primary"><i className="fa fa-pen"></i></Link>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
