import React from 'react'
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <>
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <div className="db-sidebar bg-body">
            <Sidebar/>
          </div>
          <div className="page-content">
            <Header/>
            <main id="content" className="bg-body-tertiary-01 d-flex flex-column main-content">
              <div className="dashboard-page-content">
                {children}
              </div>
              <Footer/>
            </main>
          </div>
        </div>
      </div>

      <div className="position-fixed z-index-10 bottom-0 end-0 p-10">
        <a href="#" className="gtf-back-to-top text-decoration-none bg-body text-primary bg-primary-hover text-light-hover shadow square p-0 rounded-circle d-flex align-items-center justify-content-center" title="Back To Top"><i className="fa-solid fa-arrow-up" /></a>
      </div>


    </>
  )
}

export default Layout