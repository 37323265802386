import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../components/Layout";
import { get, post } from "../../../services/ApiService";
import "../../custom-style.css";
import Loader from "../../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../../components/Breadcrumbs";
import TextArea from "antd/es/input/TextArea";
import { useParams, useNavigate } from 'react-router-dom';
import { UkDateFormater } from "../../../services/Helper";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Edit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 



    const [formData, setFormData] = useState({
        date: '',
        time: '',
        staffingLevel: '',
        despensedBy: '',
        checkedBy: '',
        drugName: '',
        type: '',
        cause: '',
        things: '',
        action: '',
        discussion: ''
    });



    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date: date
        });
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        try {

          setIsDataLoading(true);
          const res = await get(`api/external-log/near-miss/${id}`);
          setIsDataLoading(false);
          console.log(res)

          if (res.success) {
            const  data  = res.nearMissLog;
            setFormData({
                date:  data?.date,
                time: data?.time,
                staffingLevel: data?.staffingLevel,
                despensedBy: data?.despensedBy,
                checkedBy: data?.checkedBy,
                drugName: data?.drugName,
                type: data?.type,
                cause: data?.cause,
                things: data?.things,
                action: data?.action,
                discussion: data?.discussion
            })
            
          } else {
            setNotFound(true);
          }
        } catch (error) {
          setNotFound(true);
        } finally {
          setIsDataLoading(false);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const res = await post(`api/external-log/near-miss/update/${id}`, formData);
            console.log(res)
            if (res.success) {
                toast.success(res.message);
                setIsLoading(false);
                navigate('/near-miss-logs')

            } else {
                toast.error(res.error);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };
    const handleThingsChange = (things) => {
        setFormData({
            ...formData,
            things: things
        });
    };
    const handleActionChange = (action) => {
        setFormData({
            ...formData,
            action: action
        });
    };
    const handleDiscussionChange = (discussion) => {
        setFormData({
            ...formData,
            discussion: discussion
        });
    };

  return (
    <>
    <Layout>
        <div>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-0">
                            New Near Miss Log
                        </h2>
                        <Breadcrumbs/>
                    </div>

                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-8 rounded-4">

                            <div className="card-body p-7">
                                {isDataLoading &&
                                    <div className='text-center'>
                                        <Loader />
                                    </div>
                                }
                                {!isDataLoading && !isNotFound &&
                                <form className="form-border-1" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Date
                                                </label><br/>
                                                <DatePicker
                                                    name="date"
                                                    selected={formData.date}
                                                    onChange={handleDateChange}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="DD/MM/YYYY"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Time
                                                </label>
                                                <input type="text" name="time" placeholder="Type here" className="form-control" value={formData.time} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Staffing Level
                                                </label>
                                                <input type="text" name="staffingLevel" placeholder="Type here" className="form-control" value={formData.staffingLevel} onChange={handleChange}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Dispensed By
                                                </label>
                                                <input type="text" name="despensedBy" placeholder="Type here" className="form-control" value={formData.despensedBy} onChange={handleChange}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Checked By
                                                </label>
                                                <input type="text" name="checkedBy" placeholder="Type here" className="form-control" value={formData.checkedBy} onChange={handleChange}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Name and Brand of drug
                                                </label>
                                                <input type="text" name="drugName" placeholder="Type here" className="form-control" value={formData.drugName} onChange={handleChange}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    type of near miss
                                                </label>
                                                <input type="text" name="type" placeholder="Type here" className="form-control" value={formData.type} onChange={handleChange}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Possible Causes
                                                </label>
                                                <TextArea className="form-control" rows={5} name="cause" value={formData.cause} onChange={handleChange}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Things to consider
                                                </label>
                                                <ReactQuill theme="snow" value={formData.things} onChange={handleThingsChange}  />

                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Action TAKEN
                                                </label>
                                                <ReactQuill theme="snow" value={formData.action} onChange={handleActionChange}  />

                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Ptotential adverse event discussion
                                                </label>
                                                <ReactQuill theme="snow" value={formData.discussion} onChange={handleDiscussionChange}  />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end">
                                        <button type="submit" className="btn btn-primary"  disabled={isLoading}>
                                            {isLoading && <i className="fa fa-spinner fa-spin"></i>}   Publish
                                        </button>
                                    </div>
                                </form>
                                }
                                {!isDataLoading && isNotFound && 
                                    <div className="text-center">
                                    <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                                    <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default Edit