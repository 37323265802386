import React, { useState, useEffect } from "react";
import Layout from '../../../components/Layout';
import { get } from '../../../services/ApiService';
import "./style.css";
import Loader from "../../../components/Loader";
import { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";

const AssessmentTracker = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false); 
  const [tracker, setTracker] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setNotFound(false);
      const res = await get(`api/orders/assessment/tracker/${id}`);
      console.log(res)
      setIsLoading(false);
      if (res.success) {
        setTracker(res.tracker);
      } else {
        setNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      setNotFound(true);
    }
  };

  return (
    <>
      <Layout>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="row mb-9 align-items-center">
          <div className="col-12">
            <div className="row">
              <div className="col-sm-6 mb-8 mb-sm-0">
                <h2 className="fs-4 mb-0">Assessment Tracker</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isLoading && (
              <div className='text-center p-4'>
                <Loader />
              </div>
            )}
            {!isLoading && !isNotFound && (
              <>
                <div className="col-12">
                  {tracker.map((group, groupIndex) => (
                    <div className="card mb-4" key={groupIndex}>
                      <div className="card-body">
                        <h5 className="text-danger">Question No.{groupIndex + 1}</h5>
                        {group.trackerItems.map((item, index) => (
                          <div className="mb-4" key={index}>
                            <p className="tracker-title">
                              {index + 1}. {item.title}
                              <span style={{ color: 'orange', paddingLeft: '20px', fontSize: '12px' }}>
                                {new Date(item.createdAt).toLocaleTimeString()}
                              </span>
                            </p>
                            <div className="option-section">
                              {item.options && item.options.map((option, idx) => {
                                const normalizedOption = option.trim().toLowerCase();
                                const normalizedSelectedAnswer = item.selectedAnswer.trim().toLowerCase();
                                const normalizedCorrectAnswer = item.correctAnswer ? item.correctAnswer.trim().toLowerCase() : '';

                                const isSelected = normalizedOption === normalizedSelectedAnswer;
                                let isCorrect = !normalizedCorrectAnswer || (isSelected && normalizedCorrectAnswer === normalizedSelectedAnswer);

                                return (
                                  <p key={idx}>
                                    {idx + 1})
                                    <span className={
                                      isSelected
                                        ? (isCorrect ? 'selected-option-correct' : 'selected-option-wrong')
                                        : ''
                                    } style={{ marginLeft: '5px' }}>
                                      {option}
                                    </span>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {!isLoading && isNotFound && (
              <div className="text-center">
                <img src="/assets/images/no-data.png" style={{ width: '60px', opacity: '0.5' }} alt="" />
                <h6 style={{ color: '#918f8f', fontFamily: 'math', fontWeight: '500' }}>No Data Found</h6>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AssessmentTracker;
