import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { get, post } from "../../services/ApiService";
import "../custom-style.css";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Form, Input, Button, Row, Col,Select } from 'antd';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";

const { TextArea } = Input;

const MeetingEdit = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setNotFound] = useState(false); 
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState(null);



    useEffect(() => {
        fetchMeeting();
    }, [id]);

    const fetchMeeting = async () => {
        try {
            setIsDataLoading(true);
            const res = await get(`api/meetings/${id}`);

            if (res.success) {
                const meeting = res.meeting;
                const dateTime = moment(meeting.date).format('YYYY-MM-DDTHH:mm');
                setInitialValues({
                    ...meeting,
                    dateTime, 
                });
                form.setFieldsValue({
                    ...meeting,
                    dateTime,
                });
                setIsDataLoading(false);

            } else {
                toast.error(res.error);
                setIsDataLoading(false);

            }
        } catch (error) {
            toast.error('An error occurred while fetching the meeting.');
            setIsDataLoading(false);

        }
    };

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const invitees = typeof values.invitees === 'string' 
            ? values.invitees.split(',').map(email => email.trim()) 
            : values.invitees;

            const tags = typeof values.tags === 'string' 
            ? values.tags.split(',').map(email => email.trim()) 
            : values.tags;
            
            const formData = {
                ...values,
                invitees: invitees || [],
                tags: tags || [],
                date: values.dateTime, // Use the datetime string directly
            };

            const res = await post(`api/meetings//update/${id}`, formData);
            console.log(res)
            if (res.success) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };

    const initialDiscussionContent = `
    <h3>Topic 1</h3>
    <p></p>
    <h3>Topic 2</h3>
    <p></p>
    <h3>Topic 3</h3>
    <p></p>
  `;

    return (
        <Layout>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                    <div className="row">
                        <div className="col-sm-6 mb-8 mb-sm-0">
                            <h2 className="fs-4 mb-0">Edit Meeting</h2>
                            <Breadcrumbs />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-8 rounded-4">
                                <div className="card-body p-7">
                                    {isDataLoading &&
                                        <div className='text-center'>
                                            <Loader />
                                        </div>
                                    }
                                    {!isDataLoading && !isNotFound &&
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={handleSubmit}
                                        initialValues={initialValues}
                                    >
                                        <Form.Item
                                            label="Title"
                                            name="title"
                                            rules={[{ required: true, message: 'Please enter the meeting title' }]}
                                        >
                                            <Input placeholder="Type here" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Date and Time"
                                            name="dateTime"
                                            rules={[{ required: true, message: 'Please select a date and time' }]}
                                        >
                                            <input
                                                type="datetime-local"
                                                style={{ width: '100%' }}
                                                className="form-control"
                                            />
                                        </Form.Item>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Organiser"
                                                    name="organiser"
                                                    rules={[{ required: true, message: 'Please provide organiser name' }]}
                                                >
                                                    <Input placeholder="Type here" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Invitees (comma separated)"
                                                    name="invitees"
                                                    rules={[{ required: true, message: 'Please add Invitees' }]}
                                                >
                                                    <Input placeholder="Add Invitees (comma separated)" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item
                                            label="Agenda"
                                            name="agenda"
                                            rules={[{ required: true, message: 'Please add meeting agenda' }]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>

                                        <Form.Item label="Discussion" name="discussion">
                                            <ReactQuill theme="snow" defaultValue={initialDiscussionContent} className="quill-editor" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Decision"
                                            name="decision"
                                        >
                                            <ReactQuill theme="snow" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Notes"
                                            name="notes"
                                        >
                                            <ReactQuill theme="snow" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Action"
                                            name="action"
                                        >
                                            <ReactQuill theme="snow" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Next Meeting Date and Time"
                                            name="nextMeeting"
                                        >
                                            <input
                                                type="datetime-local"
                                                style={{ width: '100%' }}
                                                className="form-control"
                                            />
                                        </Form.Item>
                                        <Col span={12}>
                                        <Form.Item
                                            label="Status"
                                            name="status"
                                            rules={[{ required: true, message: 'Please select a status' }]}
                                        >
                                            <Select placeholder="Select a status">

                                                <option value="Pending">Pending</option>
                                                <option value="Scheduled">Scheduled</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Postponed">Postponed</option>
                                                <option value="Draft">Draft</option>
                                                <option value="Cancelled">Cancelled</option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Tags"
                                            name="tags"
                                        >
                                            <Input placeholder="Type here (comma separated)" />
                                        </Form.Item>
                                        </Col>



                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={isLoading}
                                            >
                                                Update
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    }
                                    {!isDataLoading && isNotFound && 
                                        <div className="text-center">
                                        <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                                        <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MeetingEdit;
