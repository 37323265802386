// Breadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
 
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Check if a segment is likely a dynamic ID (e.g., MongoDB ObjectID or similar)
  const isDynamicSegment = (segment) => /^[a-fA-F0-9]{24}$/.test(segment);

  const breadcrumbPath = [{ name: 'Dashboard', path: '/' }];

  // Build breadcrumb path dynamically
  pathnames.reduce((prevPath, currentPath, index) => {
    const currentFullPath = `${prevPath}/${currentPath}`;

    // If the current segment is a dynamic ID, stop further processing
    if (isDynamicSegment(currentPath)) {
      return currentFullPath; // Return current full path as the next base path
    }

    // For non-dynamic segments, push to breadcrumbPath array
    breadcrumbPath.push({ name: currentPath.charAt(0).toUpperCase() + currentPath.slice(1), path: currentFullPath });

    return currentFullPath;
  }, '');

  return (
    <div style={{ background: '#e1e0e0', padding: '8px', borderRadius: '10px' }}>
      {breadcrumbPath.map((breadcrumb, index) => {
        const isLast = index === breadcrumbPath.length - 1;
        return (
          <span key={breadcrumb.name}>
            {index > 0 && ' / '}
            {isLast ? (
              <span>{breadcrumb.name}</span>
            ) : (
              <Link to={breadcrumb.path} style={{ color: '#0014ff' }}>{breadcrumb.name}</Link>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
