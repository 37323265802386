import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from '../components/Layout';
import { useSelector } from 'react-redux';

const Profile = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const [credentials, setCredentials] = useState({ firstname: (isAuthenticated ? isAuthenticated.firstname:""),lastname: (isAuthenticated ? isAuthenticated.lastname:""),email: (isAuthenticated ? isAuthenticated.email:""), phone: (isAuthenticated ? isAuthenticated.phone:""),current_password:"",new_password:"",confirm_password:"" });
  const handleInput = (e) => {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <>
    <Layout>
    <div>
        <div className="row mb-9 align-items-center justify-content-between">
            <div className="col-sm-6 mb-8 mb-sm-0">
            <h2 className="fs-4 mb-0">Profile settings</h2>
            </div>
        </div>
        <div className="card mb-4 rounded-4 p-7">
            <div className="card-body pt-7 pb-0 px-0">
               
                <div className="row mx-n8">
                   
                    <div className="col-12 px-8">
                        <section className="p-xl-8">
                            <h5 className='mb-8'>General Setting</h5>
                            <form className="form-border-1">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row gx-9">
                                        <div className="col-6 mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="first-name">First
                                            name</label>
                                        <input className="form-control" type="text" placeholder="Type here" name="first-name" 
                                            value={isAuthenticated && isAuthenticated.firstname} onChange={handleInput}/>
                                        </div>
                                        <div className="col-6 mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="last-name">Last
                                            name</label>
                                        <input className="form-control" type="text" placeholder="Type here" name="last-name" 
                                            value={isAuthenticated && isAuthenticated.firstname} onChange={handleInput}/>
                                        </div>
                                        <div className="col-lg-6 mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="email">Email</label>
                                        <input className="form-control" type="email" placeholder="example@mail.com" name="email"
                                            value={isAuthenticated && isAuthenticated.email} onChange={handleInput} />
                                        </div>
                                        <div className="col-lg-6 mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="phone">Phone</label>
                                        <input className="form-control" type="tel" placeholder="07918 23232" name="phone"
                                            value={isAuthenticated && isAuthenticated.phone} onChange={handleInput} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <button className="btn btn-primary" type="submit">
                                Save changes
                            </button>
                            </form>
                            <hr className="my-8" />
                            <div className="row">
                                <div className="col-lg-8">
                                    <h5 className='mb-8'>Password Setting</h5>
                                    <div className="mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase">
                                            Current Password
                                        </label>
                                        <input className="form-control" type="password" placeholder="Type here" name="current_password"onChange={handleInput} />
                                    </div>
                                    <div className="mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase">
                                            New Password
                                        </label>
                                        <input className="form-control" type="text" placeholder="Type here" name="new_password"onChange={handleInput}  />
                                    </div>
                                    <div className=" mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase">Confirm Password</label>
                                        <input className="form-control" type="email" placeholder="example@mail.com" name="confirm_password"onChange={handleInput}  />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">
                                Save changes
                            </button>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>


    </Layout>
    </>
  )
}

export default Profile