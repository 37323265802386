import React from 'react';
import { Link } from 'react-router-dom';
import { DateTimeFormater } from '../services/Helper';// Adjust import as needed
// Adjust import as needed

const NotificationDropdown = ({ 
  notifications, 
  type, 
  isMark, 
  icon, 
  title, 
  deleteNotifications,
  deleteSingleNotifications
}) => {


  return (
    <div className="dropdown no-caret px-4 mx-4 py-2">
      <Link
        className="dropdown-toggle fs-5 text-decoration-none fw-500 lh-1 animation-shake position-relative"
        data-bs-toggle="dropdown"
        title={title}
      >
        <i className={icon} />
        <span className="badge text-bg-dark rounded-circle">{notifications.length}</span>
      </Link>
      <div className="dropdown-menu dropdown-menu-end" style={{ minWidth: '300px' }}>
        {notifications && notifications.length > 0 ? (
          <>
            <div className='col-12 notification-header'>
              <div className='row'>
                <div className='col-8'>
                  <p className='notification-title'>{title}</p>
                </div>
                <div className='col-4 text-end'>
                  <Link
                    className={`mark-read badge bg-warning btn-mark-read mark-${type}`}
                    onClick={() => deleteNotifications(type)}
                    disabled={isMark}
                  >
                    Mark all read
                  </Link>
                </div>
              </div>
            </div>
            <div style={{ overflowY: 'scroll', height: '300px' }}>
              {notifications.map((notification, index) => (
                <React.Fragment key={notification._id}>
                  <div className="d-flex justify-content-between align-items-start mb-2 notification-bar">
                    <Link
                      className="dropdown-item d-flex flex-column w-100 notification-link"
                      to={notification.redirect}
                    >
                      <h6 className='text-primary m-0 p-0' style={{ fontSize: '13px' }}>{notification.subject}</h6>
                      <p className='m-0 p-0' style={{ fontSize: '12px', color: 'black' }}>{notification.message}</p>
                      <p className='m-0 p-0' style={{ fontSize: '10px' }}>
                        <i className="fa fa-clock" aria-hidden="true"></i> {DateTimeFormater(notification.createdAt)}
                      </p>
                    </Link>
                    <button
                      className="btn btn-link p-0 ml-2"
                      aria-label="Close"
                      style={{ margin: '5px', color: '#a93535' }}
                      onClick={() => deleteSingleNotifications(notification._id, notification.type)}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                  <hr />
                </React.Fragment>
              ))}
            </div>
          </>
        ) : (
          <h6 className='text-center' style={{ fontSize: '13px' }}>No Notification Found</h6>
        )}
      </div>
    </div>
  );
};

export default NotificationDropdown;
