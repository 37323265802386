import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { postFile } from '../../../services/ApiService';

const FileUploadModal = ({ visible, onClose,fetchData }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [uploading, setUploading] = useState(false);

  // Function to handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file type based on extension if MIME type is not recognized
    const allowedExtensions = ['.pdf', '.doc', '.docx', '.txt'];
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      message.error('Only PDF, Word, and TXT files are allowed');
      return;
    }

    // File size validation (2MB limit)
    if (selectedFile.size > 2 * 1024 * 1024) {
      message.error('File size exceeds 2MB limit');
      return;
    }

    setFile(selectedFile);

  };

  const handleUpload = async () => {

    if (!file) {
      message.error('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      const res = await axios.post('https://pharma-server.myprivatechemist.com/api/external-log/files/upload', formData);
      setUploading(false);

      if(res.data.success){
        message.success('File uploaded successfully');
        setFile('')
        setFileName('');
        fetchData();
      }else{
        message.error('Failed to uplaod file');
      }

      onClose(); // Close modal after successful upload
    } catch (error) {
      setUploading(false);
      console.error('Upload failed:', error);
      message.error('Upload failed');
    }
  };

  // Function to handle file deletion
  const handleDelete = () => {
    setFile(null); // Clear selected file
  };

  return (
    <Modal
      title="Upload File"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="upload" type="primary" onClick={handleUpload} disabled={uploading}>
          Upload
        </Button>
      ]}
    >
      {file ? (
        <div className="file-preview d-flex">
          <p>{file.name}</p>
          <Button type="danger" onClick={handleDelete} icon={<DeleteOutlined />} ></Button>
        </div>
      ) : (
        <div className="file-input">
          <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx,.txt" />
          <p>Select a file (PDF, Word, TXT)</p>
        </div>
      )}
    </Modal>
  );
};

export default FileUploadModal;
