import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const renderPageNumbers = () => {
        const pages = [];
        const pageLimit = 5; // Adjust the number of pages shown

        // Case 1: Total pages <= pageLimit
        if (totalPages <= pageLimit) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(renderPageItem(i));
            }
        } else {
            // Case 2: More than pageLimit pages
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pages.push(renderPageItem(1));
                pages.push(<li key="leftEllipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, start + pageLimit - 1);

            for (let i = start; i <= end; i++) {
                pages.push(renderPageItem(i));
            }

            if (rightEllipsis) {
                pages.push(<li key="rightEllipsis" className="page-item disabled"><span className="page-link">...</span></li>);
                pages.push(renderPageItem(totalPages));
            }
        }

        return pages;
    };

    const renderPageItem = (pageNumber) => (
        <li key={pageNumber} className={`page-item mx-3 ${pageNumber === currentPage ? 'active' : ''}`}>
            <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(pageNumber); }}>
                {pageNumber < 10 ? `0${pageNumber}` : pageNumber}
            </a>
        </li>
    );

    return (
        <nav aria-label="Page navigation example" className="mt-6 mb-4">
            <ul className="pagination justify-content-start">
                {currentPage > 1 && (
                    <li className="page-item mx-3">
                        <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(currentPage - 1); }}>
                            <i className="far fa-chevron-left" />
                        </a>
                    </li>
                )}
                {renderPageNumbers()}
                {currentPage < totalPages && (
                    <li className="page-item mx-3">
                        <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(currentPage + 1); }}>
                            <i className="far fa-chevron-right" />
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;
