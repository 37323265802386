import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, del } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import Pagination from '../../components/Pagination';
import toast, { Toaster } from 'react-hot-toast';
import OrderTable from "./PharmaOrderTable";
import Breadcrumbs from "../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

const PharmaOrders = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [orders, setOrders] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderCount, serOrderCount] = useState(0);
    const [filters, setFilters] = useState({
        orderId: '',
        customer: '',
        status: 'All',
        limit: '20',
        dateRange: [null, null],
        sortOrder: 'newest'
    });
    useEffect(() => {
        fetchData()
    }, [currentPage, filters]);

    const fetchData = async () => {

        try {
            setIsLoading(true);
            const Body = {
              page: currentPage,
              ...filters,
              startDate: filters.dateRange[0] ? filters.dateRange[0].toISOString() : null,
              endDate: filters.dateRange[1] ? filters.dateRange[1].toISOString() : null
            };
            const res = await post(`api/orders/p-med`, Body);
            if (res.success) {
              setIsLoading(false);
              setOrders(res.orders);
              setTotalPages(res.totalPages);
              setNotFound(false);
              serOrderCount(res.orderCount);
            } else {
              setOrders([]);
              setTotalPages(1);
              setIsLoading(false);
              setNotFound(true);
            }
        } catch (error) {
            setOrders([]);
            setTotalPages(1);
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
    };


    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };
    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;
        setFilters({
            ...filters,
            dateRange: [start, end]
        });
    };
    const handleSortOrderChange = (e) => {
        setFilters({
            ...filters,
            sortOrder: e.target.value
        });
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        setFilters({
            ...filters,
            [event.target.name]: event.target.value
        });
    };
    const resetFilters = () => {
        setFilters({
            orderId: '',
            customer: '',
            status: 'All',
            limit: '20',
            dateRange: [null, null], // Ensure dateRange is properly reset
            sortOrder: 'newest'
        });
        setCurrentPage(1); 
        setOrders([]); 
        setTotalPages(1); 
    };


  return (
    <>
        <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="col-sm-6 mb-8 mb-sm-0">
                <h2 className="fs-4 mb-0">Order List (<span className="fs-4 text-primary">{orderCount}</span>)</h2>
                <Breadcrumbs />
                </div>
                <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                    {/* <button className="btn btn-primary btn-sm">Hard Refresh</button> */}
                </div>
            </div>
            <div className="card mb-4 rounded-4 p-7">
                <div className="card-header bg-transparent px-0 pt-0 pb-7">
                <div className="row align-items-center justify-content-between mb-4">
                    <div className="col-lg-3 col-3 col-sm-12 col-md-6 mr-auto mb-md-0 mb-6">
                        <input type="text" placeholder="Search by order id..." name="orderId" value={filters.orderId} onChange={handleFilterChange}  className="form-control bg-input border-0" />
                    </div>
                    <div className="col-lg-3 col-3 col-md-6 col-sm-12 mr-auto mb-md-0 mb-6">
                            <input type="text" placeholder="Search by customer..." name="customer" value={filters.customer} onChange={handleFilterChange}  className="form-control bg-input border-0" />
                    </div>
                    <div className="col-lg-3 col-md-6 col-3 col-sm-12 mr-auto mb-md-0 mb-6">
                            <select className="form-select" name="status" value={filters.status} onChange={handleFilterChange}>
                                <option value='All'> Status</option>
                                <option value='PENDING'>PENDING P APPROVAL</option>
                                <option value='APPROVED'>APPROVED</option>
                            </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-3 col-sm-12 mr-auto mb-md-0 mb-6">
                            <select className="form-select" name="limit" value={filters.limit} onChange={handleLimitChange}>
                                <option value="10">Show 10</option>
                                <option value="20">Show 20</option>
                                <option value="50">Show 50</option>
                                <option value="100">Show 100</option>
                            </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-3">
                        <DatePicker
                            selected={filters.dateRange[0]}
                            onChange={handleDateRangeChange}
                            startDate={filters.dateRange[0]}
                            endDate={filters.dateRange[1]}
                            selectsRange
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date range"
                            className="form-control bg-input border-0"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="sortOrder" value={filters.sortOrder} onChange={handleSortOrderChange}>
                            <option value="newest">Newest to Oldest</option>
                            <option value="oldest">Oldest to Newest</option>
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <Link className="mt-4 text-danger" onClick={resetFilters}><i className="fa fa-sync" style={{fontSize:'20px',marginTop:'15px'}}></i></Link>
                    </div>
                </div>
                </div>
                <div className="card-body px-0 pt-7 pb-0">
                    {isLoading  ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ):(
                        orders.length > 0 && <OrderTable orders={orders} />
                    )}
                    {!isLoading && isNotFound && 
                        <div className="text-center">
                        <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
                        <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                        </div>
                    }
                </div>
            </div>
            {totalPages && totalPages > 1 && 
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            }
        </Layout>
    </>
  )
}

export default PharmaOrders