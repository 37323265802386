import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, del } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import { Link , useParams} from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";

const AssignQuestions = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isAssigning, setIsAssigning] = useState(false);
    const [isNotFound, setNotFound] = useState(false); 
    const [questions, setQuestions] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);


    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const questionsRes = await get('api/questions/list');
            const productRes = await get(`api/products/${id}`);

            setIsLoading(false);
            if (questionsRes.success && productRes.success) {
                setQuestions(questionsRes.questions);
                setSelectedQuestions(productRes.data.questions); 
                setNotFound(false);
            } else {
                setQuestions([]);
                setNotFound(true);
            }
        } catch (error) {
            setIsLoading(false);
            setNotFound(true);
            console.log(error);
        }
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedQuestions((prev) => [...prev, value]);
        } else {
            setSelectedQuestions((prev) => prev.filter((id) => id !== value));
        }
    };
    const handleAssignQuestions = async () => {
        try {
            setIsAssigning(true);
            const res = await post(`api/products/${id}/assign-questions`, { questionIds: selectedQuestions });
            setIsAssigning(false);
            if (res.success) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        } catch (error) {
            setIsAssigning(false);
            toast.error('An error occurred while assigning questions to the product.');
        }
    };
  return (
    <>
        <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="col-md-6 mb-8 mb-md-0">
                    <h2 className="fs-4 mb-0">Assign Question</h2>
                    <Breadcrumbs />
                </div>

            </div>
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="card mb-4 rounded-4 p-7">
                    <div className="card-body px-0 pt-7 pb-0">
                        <div className="row mb-4">
                            <div className="col-6">
                                <h6 className="fs-4 mb-0">Questions</h6>
                                <p>List of questions which will be used on web page and eBay message assessment</p>
                            </div>
                            <div className="col-6 text-end">
                                <Link to={`/assessment/demo/${id}`} className="btn btn-dark btn-sm">Customer Assessment</Link>
                            </div>
                        </div>
                        {isLoading ? (
                        <div className='text-center'>
                            <Loader />
                        </div>
                        ):(
                            <>

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                            {questions.map((question, index) => (
                                <div className="accordion-item mb-4">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                         
                                          <div className="d-flex">
                                           <div className="form-check">
                                                <input className="form-check-input rounded-0 ms-0" type="checkbox" id={`transactionCheck-${index}`} 
                                                    value={question._id} onChange={handleCheckboxChange} checked={selectedQuestions.includes(question._id)} style={{border:'1px solid black'}}/>
                                                <label className="form-check-label" htmlFor={`transactionCheck-${index}`} />
                                            </div>
                                            <div className="" style={{marginLeft:'10px'}}> {index + 1} -  {question.question}</div>
                                            <div className="" style={{marginLeft:'10px'}}>
                                            {question.tags && 
                                                question.tags.map((tag) => (
                                                    <span className="badge bg-dark  m-2">{tag}</span>
                                                ))
                                            }
                                            </div>
                                          </div>
                                        </button>
                                    </h2>
                                    <div id={`flush-collapse-${index}`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                        <div>
                                            <table  className="table table-bordered" cellSpacing="1px" padding="20px">
                                                <tbody>
                                                    <tr>
                                                        <th width="20%">Input</th>
                                                        <td width="80%">{question.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Options</th>
                                                        <td width="80%">
                                                        {question.options && question.options.length > 0 ?(
                                                            question.options.map((option, index) => (
                                                                    <><span>{index +1} - {option}</span><br/></>
                                                                ))
                                                                ):(
                                                                    <span className="text-muted">N/A</span>
                                                                )

                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Description</th>
                                                        <td width="80%"><span style={{fontWeight:'400'}} dangerouslySetInnerHTML={{ __html: question.description }} /></td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Answer</th>
                                                        <td width="80%">{question.correctAnswer}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">eBay Message</th>
                                                        <td width="80%">{question?.messageQuestion}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                            {!isLoading && questions.length === 0 && 
                                <div className="text-center">
                                <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
                                <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                </div>
                            }

                            </>
                        )}
                    </div>
                </div>
            </div>
            {(selectedQuestions && selectedQuestions.length > 0)  && 
                <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end mt-4">
                    <button className="btn btn-primary" disabled={isAssigning} onClick={handleAssignQuestions}>
                        {isAssigning && <i className="fa fa-spinner fa-spin"></i>} Assign Now
                    </button>
                </div>
            }
        </Layout>
    </>
  )
}

export default AssignQuestions