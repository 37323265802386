// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavigationProvider,useNavigation } from './context/NavigationContext';
import PrivateRoute from './pages/auth/PrivateRoute';
import Dashboard from './pages/Dashboard';
import ProductList from './pages/Products/ProductList';
import ProductEdit from './pages/Products/ProductEdit';
import QuestionAdd from './pages/Questions/QuestionAdd';
import QuestionList from './pages/Questions/QuestionList';
import QuestionEdit from './pages/Questions/QuestionEdit';
import AssignQuestions from './pages/Products/AssignQuestions';
import OrderList from './pages/Orders/OrderList';
import OrderDetail from './pages/Orders/OrderDetail';
import OrderEdit from './pages/Orders/OrderEdit';
import PharmaOrders from './pages/Orders/PharmaOrders';
import Questionnaire from './pages/assessments/Questionnaire';
import VariationList from './pages/Variations/VariationList';
import OrderAssessment from './pages/Orders/OrderAssessment';
import Login from './pages/auth/Login';
import Profile from './pages/Profile';
import Notes from './pages/notes/Notes';
import DemoAssessment from './pages/assessments/DemoAssessment';
import Orders from './pages/Orders/AssessmentOrder/Orders';
import DemoChat from './pages/Orders/DemoChat';
import Assessment from './pages/Orders/Assessment/Assessment';
import EbayQuestionDemo from './pages/assessments/EbayQuestionDemo';
import Add from './pages/externalLogs/nearMissLogs/Add';
import List from './pages/externalLogs/nearMissLogs/List';
import Edit from './pages/externalLogs/nearMissLogs/Edit';
import FileList from './pages/externalLogs/fileManager/FileList';
import UserList from './pages/users/UserList';
import UserAdd from './pages/users/UserAdd';
import UserEdit from './pages/users/UserEdit';
import MeetingAdd from './pages/meetings/meetingAdd';
import MeetingList from './pages/meetings/meetingList';
import MeetingEdit from './pages/meetings/MeetingEdit';
import MeetingDetail from './pages/meetings/MeetingDetail';
import SiteSetting from './pages/settings/SiteSetting';
import AssessmentTracker from './pages/Orders/Assessment/AssessmentTracker';
const App = () => {
    return (

      <Router>
        
        <Routes>
            <Route path='/login' element={<Login/>} />
            <Route path='/questionnaire/:id' element={<Questionnaire/>} />
            <Route path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>} />
            <Route path='/products' element={<PrivateRoute><ProductList/></PrivateRoute>} />
            <Route path='/products/edit/:id' element={<PrivateRoute><ProductEdit/></PrivateRoute>} />
            <Route path='/products/assign-question/:id' element={<PrivateRoute><AssignQuestions/></PrivateRoute>} />
            <Route path='/questions/add' element={<PrivateRoute><QuestionAdd/></PrivateRoute>} />
            <Route path='/questions' element={<PrivateRoute><QuestionList/></PrivateRoute>} />
            <Route path='/questions/edit/:id' element={<PrivateRoute><QuestionEdit/></PrivateRoute>} />
            <Route path='/orders' element={<PrivateRoute><OrderList/></PrivateRoute>} />
            <Route path='/orders/edit/:id' element={<PrivateRoute><OrderEdit/></PrivateRoute>} />
            <Route path='/orders/pharma-medicine' element={<PrivateRoute><PharmaOrders/></PrivateRoute>} />
            <Route path='/orders/:id' element={<PrivateRoute><Assessment/></PrivateRoute>} />
            <Route path='/order/assessment/:id' element={<PrivateRoute><Assessment/></PrivateRoute>} />
            <Route path='/variations' element={<PrivateRoute><VariationList/></PrivateRoute>} />
            <Route path='/profile' element={<PrivateRoute><Profile/></PrivateRoute>} />
            <Route path='/notes' element={<PrivateRoute><Notes/></PrivateRoute>} />
            <Route path='/assessment/demo/:id' element={<PrivateRoute><DemoAssessment/></PrivateRoute>} />
            <Route path='/orders/assessment-required' element={<PrivateRoute><Orders/></PrivateRoute>} />
            <Route path='/chat/demo' element={<PrivateRoute><DemoChat/></PrivateRoute>} />
            <Route path='/assessment/demo' element={<PrivateRoute><Assessment/></PrivateRoute>} />
            <Route path='/ebay/question/demo/:id' element={<PrivateRoute><EbayQuestionDemo/></PrivateRoute>} />
            <Route path='/near-miss-logs/add' element={<PrivateRoute><Add/></PrivateRoute>} />
            <Route path='/near-miss-logs' element={<PrivateRoute><List/></PrivateRoute>} />
            <Route path='/near-miss-logs/edit/:id' element={<PrivateRoute><Edit/></PrivateRoute>} />
            <Route path='/file-manager' element={<PrivateRoute><FileList/></PrivateRoute>} />
            <Route path='/users' element={<PrivateRoute><UserList/></PrivateRoute>} />
            <Route path='/users/add' element={<PrivateRoute><UserAdd/></PrivateRoute>} />
            <Route path='/users/edit/:id' element={<PrivateRoute><UserEdit/></PrivateRoute>} />
            <Route path='/meetings/add' element={<PrivateRoute><MeetingAdd/></PrivateRoute>} />
            <Route path='/meetings' element={<PrivateRoute><MeetingList/></PrivateRoute>} />
            <Route path='/meetings/edit/:id' element={<PrivateRoute><MeetingEdit/></PrivateRoute>} />
            <Route path='/meetings/detail/:id' element={<PrivateRoute><MeetingDetail/></PrivateRoute>} />
            <Route path='/site-setting' element={<PrivateRoute><SiteSetting/></PrivateRoute>} />
            <Route path='/assessment/tracker/:id' element={<PrivateRoute><AssessmentTracker/></PrivateRoute>} />
            
        </Routes>
    </Router>

    );
};

export default App;
