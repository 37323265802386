import React, { useState,  } from "react";
import Layout from '../../components/Layout'
import "../custom-style.css";
import 'react-quill/dist/quill.snow.css';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";

const Notes = () => {



  return (
    <>
    <Layout>
        <div>

            <div className="row mb-9 align-items-center">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6 mb-8 mb-sm-0">
                            <h2 className="fs-4 mb-0">
                                Notes
                            </h2>
                            <Breadcrumbs/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-3">
                            <div className="bt-sec text-center note-btn-add">
                                <p className="m-0 p-0"><i className="fa fa-plus"></i></p>
                                <p className="m-0 p-0">New Note</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card note-card">
                                <dyv className="card-body">
                                    <p className="m-0 p-0 note-date">Audit</p>
                                    <div className="row">
                                        <div className="col-10">
                                            <h6 className="m-0 p-0 note-title">Page title here</h6>
                                        </div>
                                        <div className="col-2">
                                            <Link className="note-edit-btn"><i className="fa fa-pen note-edit-icon"></i></Link>
                                        </div>
                                    </div>
                                    <hr className=" p-0"/>
                                    <div className="note-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </div>
                                    <div className="note-footer mt-4 mb-4">
                                        <p className="m-0 p-0 note-date">  <i className="fa fa-clock"></i> 12/03/2024 </p>
                                    </div>
                                </dyv>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    </Layout>
    </>
  )
}

export default Notes