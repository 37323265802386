import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectUserId } from "../../redux/selector";

const Cards = ({ users, showDelModal }) => {
    const userId = useSelector(selectUserId);


  return (
    <>
      <div className="row">
        {users && users.map((user,index) =>(
            <div className="col-sm-6 col-lg-4 mb-9" key={index}>
                <div className="card card-user rounded-4">
                    <div className="" style={{position:'absolute',zIndex:'1000'}}>
                        {userId !== user._id && 
                        <>
                        <Link to={`/users/edit/${user._id}`} className="badge bg-primary m-4">
                            <i className="far fa-pen"></i>
                        </Link>
                        <Link className="badge bg-danger m-4" onClick={() => showDelModal(user._id)}>
                            <i className="far fa-trash"></i>
                        </Link>
                        </>
                        }

                    </div>
                    <div className="card-header text-center">
                        <img className="img-avatar loaded" src="/assets/images/dashboard/avatar-1.png" data-src="../assets/images/dashboard/avatar-1.png" alt="Marvin McKinney" width={112} height={112} loading="lazy" data-ll-status="loaded" />
                    </div>
                    <div className="card-body  p-7">
                        <h5 className="card-title text-center mt-12 fs-6 mb-6">{user?.firstname} {user?.lastname}</h5>
                        <div className="card-text text-center text-muted">
                            <p className="m-0">{user?.phone}</p>
                            <p className="mb-0">{user?.email}</p>
                            <span className="badge bg-primary">{user?.role}</span>
                        </div>

                    </div>
                </div>
            </div>
        ))}


      </div>
    </>
  );
};

export default Cards;
