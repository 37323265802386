import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout';
import { get, post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";

const SiteSetting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [setting, setSetting] = useState({
    autoApprovalEnabled: false,
    autoApprovalStartTime: '',
    autoApprovalEndTime: '',
    userId:''
  });
  const [users, setUsers] = useState([]);
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await get(`api/settings/site`);
      setIsLoading(false);

      if (res.success) {
        setSetting(res.data);
        setUsers(res.users)
        setNotFound(false);
      } else {
        setNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      setNotFound(true);
      console.error(error.message);
    }
  };

  const handleCheckboxChange = (e) => {
    setSetting({
      ...setting,
      autoApprovalEnabled: e.target.checked
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSetting({
      ...setting,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    try {
      const res = await post(`api/settings/site`, setting);
      setIsSubmit(false);

      if (res.success) {
        toast.success('Settings updated successfully');
      } else {
        toast.error('Failed to update settings');
      }
    } catch (error) {
      setIsSubmit(false);
      toast.error('An error occurred while updating the settings');
      console.error(error.message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Layout>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="row mb-9 align-items-center justify-content-between">
          <div className="col-md-4 mb-8 mb-md-0">
            <h2 className="fs-4 mb-0">Site Setting</h2>
            <Breadcrumbs />
          </div>
        </div>
        <div className="card mb-4 rounded-4 p-7">
          <div className="card-body pt-7 pb-0 px-0">
            <section className="p-xl-8">
              <form className="form-border-1" onSubmit={handleSubmit}>
                <div className="row border-bottom py-8">
                  <div className="col-md-5">
                    <h5>Auto Approval</h5>
                    <p className="text-muted w-80">
                      Auto approval for p-med order, we need to set a time window to capture order for approval.
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="form-check mb-6">
                      <input
                        className="form-check-input rounded-0"
                        type="checkbox"
                        id="autoApprovalEnabled"
                        checked={setting?.autoApprovalEnabled}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="autoApprovalEnabled">
                        Order auto approval
                      </label>
                    </div>
                    {setting?.autoApprovalEnabled && (
                        <>
                            <div className="mb-3">
                            <label htmlFor="autoApprovalStartTime" className="form-label">
                                Start Time
                            </label>
                            <input
                                type="time"
                                className="form-control"
                                id="autoApprovalStartTime"
                                name="autoApprovalStartTime"
                                value={setting?.autoApprovalStartTime}
                                onChange={handleInputChange}
                                required
                            />
                            </div>
                            <div className="mb-3">
                            <label htmlFor="autoApprovalEndTime" className="form-label">
                                End Time
                            </label>
                            <input
                                type="time"
                                className="form-control"
                                id="autoApprovalEndTime"
                                name="autoApprovalEndTime"
                                value={setting?.autoApprovalEndTime}
                                onChange={handleInputChange}
                                required
                            />
                            </div>
                            <div className="mb-3">
                            <label htmlFor="user" className="form-label">
                               User
                            </label>
                            <select className="form-control" name="userId" id="userId" required onChange={handleInputChange} value={setting?.userId}>
                                <option value="">Select User</option>
                                {users && users.map((user, index) => (
                                    <option key={index} value={`${user._id}`}>
                                        {user.firstname} {user.lastname}
                                    </option>
                                ))}
                            </select>
                            </div>
                        </>
                    )}

                  </div>
                </div>
                <button className="btn btn-primary mt-4" type="submit" disabled={isSubmit}>
                  {isSubmit ? 'Saving...' : 'Save all changes'}
                </button>
              </form>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SiteSetting;
