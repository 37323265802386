import React from 'react';
import { Link } from "react-router-dom";

const VariationTable = ({ variations, }) => {
    return (
        <table className="table table-hover align-middle table-nowrap mb-0">
            <tbody>
                {variations.map((variation, index) => (
                    <tr key={index}>
                        <td>
                            <div className="d-flex align-items-center flex-nowrap">
                                <p className="fw-semibold text-body-emphasis mb-0">
                                    {variation.SKU}
                                </p>
                            </div>
                        </td>
                        <td>{variation.weight}</td>
                        <td>{variation.format}</td>
                        <td>{variation.limit}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default VariationTable;
