import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { post } from "../../services/ApiService";
import "./style.css";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { get } from "../../services/ApiService";

const MeetingDetail = () => {
    const { id } = useParams();
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setNotFound] = useState(false); 
    const [meeting, setMeeting] = useState(''); 

    useEffect(() => {
        fetchMeeting();
    }, [id]);

    const fetchMeeting = async () => {
        try {
            setIsDataLoading(true);
            const res = await get(`api/meetings/${id}`);

            if (res.success) {
                const meeting = res.meeting;
                const dateTime = moment(meeting.date).format('YYYY-MM-DDTHH:mm');
                setMeeting({
                    ...meeting,
                    dateTime, 
                });

                setIsDataLoading(false);

            } else {
                toast.error(res.error);
                setIsDataLoading(false);

            }
        } catch (error) {
            toast.error('An error occurred while fetching the meeting.');
            setIsDataLoading(false);

        }
    };
    const MeetingMonth = ({ date }) => {
        const month = moment(date).format('MMM'); 
        return month;
    };

    const MeetingDay = (dateString) => {
        const date = new Date(dateString);
        const dayOfMonth = date.getUTCDate();
        const formattedDay = dayOfMonth.toString().padStart(2, '0');
        return formattedDay
    };
    const formatTime = (dateString) => {
        return moment(dateString).format('h:mm A'); // "h:mmA" gives "11:00AM"
    };





    return (
        <Layout>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                    <div className="row">
                        <div className="col-sm-6 mb-8 mb-sm-0">
                            <h2 className="fs-4 mb-0"> Meeting</h2>
                            <Breadcrumbs />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="card shadow" style={{border:'transparent',borderRadius:'10px'}}>
                    <div className="card-body">
                        <div className="d-flex mb-4">
                            <div className="calendar">
                                {meeting?.date && 
                                    <>
                                    <span className="calendar-month">{ MeetingMonth(meeting?.date)}</span>
                                    <span className="calendar-day">{MeetingDay(meeting?.date)}</span>
                                    </>
                                }
                            </div>
                            <div className="fs-10 ms-2 flex-1 ps-3">
                                <h5 className="fs-9 text-capitalize mb-0">{meeting?.title}</h5>
                                <p className="text-1000 mb-0">{meeting?.agenda}</p>

                                <p className="mb-0 text-capitalize">by
                                    <Link className="ms-1 text-primary" >
                                    &nbsp; {meeting?.organiser}
                                    </Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            {meeting?.discussion &&
                            <>
                            <p className="fs-9 mb-3 mt-3 text-dark">Discussion Points</p>
                            <div className="fs-9 mb-3">              
                                <div dangerouslySetInnerHTML={{ __html: meeting?.discussion }} />
                            </div>
                            </>
                            }
                            {meeting?.decision &&
                            <>
                            <p className="fs-9 mb-3  text-dark">Decisions Made</p>
                            <div className="fs-9 mb-3">
                                <div dangerouslySetInnerHTML={{ __html: meeting?.decision }} />
                            </div>
                            </>
                            }
                            {meeting?.action &&
                            <>
                            <p className="fs-9 mb-3  text-dark">Action Items</p>
                            <div className="fs-9 mb-3">
                                <div dangerouslySetInnerHTML={{ __html: meeting?.action }} />
                            </div>
                            </>
                            }
                            {meeting?.action &&
                            <>
                            <p className="fs-9 mb-3  text-dark">Additional Notes</p>
                            <div className="fs-9 mb-3">
                                <div dangerouslySetInnerHTML={{ __html: meeting?.notes }} />
                            </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MeetingDetail;
