import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import Loader from "../../components/Loader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { get, post, del } from "../../services/ApiService";
import { useParams } from "react-router-dom";

const Questionnaire = () => {
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const nextQuestionRef = useRef(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [tracker, setTracker] = useState([]);
  const [warnings, setWarnings] = useState({});
  const [hasWarnings, setHasWarnings] = useState(false);

  useEffect(() => {
    fetchQuestions();
  }, [id]);
  useEffect(() => {
    if (nextQuestionRef.current) {
      nextQuestionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [answeredQuestions]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const res = await get(`api/orders/assessment/${id}`);
      if (res.success) {
        setQuestions(res.questions);
        setLoading(false);
        setNotFound(false);
      } else {
        setQuestions([]);
        setLoading(false);
        setNotFound(true);
        if(res.isComplete){
          setIsAssessmentCompleted(true);
        }
      }
    } catch (error) {
      setQuestions([]);
      setLoading(false);
      setNotFound(true);
    }
  };

  const handleAnswer = (questionId, selectedAnswer) => {
    setEditingQuestionId(null);
    const answeredQuestion = questions.find(
      (question) => question._id === questionId
    );
    const timestamp = new Date().toISOString();

    // Track changes in the tracker state
    setTracker((prev) => {
      // const existingTrackerIndex = prev.findIndex(t => t._id === questionId);
      const updatedTrackerEntry = { _id: questionId, selectedAnswer, timestamp };
      return [...prev, updatedTrackerEntry];
    });

    if (answeredQuestion) {
      const updatedAnsweredQuestion = { ...answeredQuestion, selectedAnswer };
      setAnsweredQuestions((prev) => {
        const existingAnswerIndex = prev.findIndex(q => q._id === questionId);
        if (existingAnswerIndex > -1) {
          const updatedAnswers = [...prev];
          updatedAnswers[existingAnswerIndex] = updatedAnsweredQuestion;
          return updatedAnswers;
        } else {
          return [...prev, updatedAnsweredQuestion];
        }
      });
      const selectOption = selectedAnswer;
      const questionCorrectAnswer = answeredQuestion.correctAnswer;
      const questionWarning = answeredQuestion.warning;
      if(questionCorrectAnswer && questionWarning && questionWarning !== ''){
        if(questionCorrectAnswer === selectOption){
          setWarnings((prev) => {
            const updatedWarnings = { ...prev };
            delete updatedWarnings[questionId];
            return updatedWarnings;
          });
        }else{
          setWarnings((prev) => ({ ...prev, [questionId]: questionWarning }));
          setEditingQuestionId(answeredQuestion._id);
        }
      }
      const newProgress =
        ((answeredQuestions.length + 1) / questions.length) * 100;
      setProgressPercentage(newProgress);
    }
    setHasWarnings(Object.keys(warnings).length > 0);
  };
  
  const getNextQuestionId = () => {
    const answeredIds = answeredQuestions.map((question) => question._id);
    const nextQuestion = questions.find(
      (question) => !answeredIds.includes(question._id)
    );
    return nextQuestion ? nextQuestion._id : null;
  };

  const renderQuestion = (questionId) => {
    const question = questions.find((question) => question._id === questionId);
    if (!question) {
      return null; // Return null if no more questions
    }
    const warningMessage = warnings[questionId];
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={question._id}
        ref={question._id === getNextQuestionId() ? nextQuestionRef : null}
      >
        <div className="d-flex flex-column flex-sm-row gap-3">
          <img
            src="/assets/images/chat/user.jpeg"
            className="img-fluid rounded-3"
            width={70}
            style={{height:'45px',marginTop:'10px'}}
            alt=""
          />
          <div className="w-100">
            <label className="pb-1 fs-16 text-black m-3">
              {question.question}
            </label>
            {question.description !=='' && 
              <div className="question_description"
                  dangerouslySetInnerHTML={{ __html: question.description }}
              />
            }
            {renderInput(question)}
            {warningMessage && (
              <div className="alert alert-danger question-warning" role="alert">
                {warningMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderInput = (question) => {
    const currentAnswer = answeredQuestions.find(q => q._id === question._id)?.selectedAnswer || '';
    switch (question.type) {
      case "select":
        return (
          <select
            className="form-select bordr-color-main rounded-5 fs-14 pad-14-20 w-300"
            value={currentAnswer}
            onChange={(e) => handleAnswer(question._id, e.target.value)}
          >
            <option value="" disabled>Please Select</option>
            {question.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "radio":
        return (
          <div className="d-flex align-items-center gap-2">
            {question.options.map((option) => (
              <div
                key={option}
                className="form-check bordr-color-main border pad-14-20 rounded-5"
              >
                <input
                  className="form-check-input colorchange ms-0"
                  type="radio"
                  name={question._id}
                  id={option}
                  value={option}
                  checked={currentAnswer === option}
                  onClick={(e) => handleAnswer(question._id, e.target.value)}
                />
                <label className="form-check-label fs-14 ps-2" htmlFor={option}>
                  {option}
                </label>
              </div>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            rows={3}
            cols={3}
            type="text"
            className="form-control fs-14 resize-none border-0 bg-transparent"
            placeholder="Write here..."
            defaultValue={currentAnswer}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Prevents the default behavior (like line break in textarea)
                handleAnswer(question._id, e.target.value);
              }
            }}
            onBlur={(e) => handleAnswer(question._id, e.target.value)} // Handle blur event to mark answer
          />
        );
      default:
        return null;
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmit(true);
      const Body = {
        customerResponses: answeredQuestions,
        tracker
      };
      const res = await post(`api/orders/assessment/taking/${id}`, Body);

      if (res.success) {
        setIsSubmit(false);
        setIsComplete(true);
      } else {
        setIsSubmit(false);
      }
    } catch (error) {
      setIsSubmit(false);
    }
  };

  return (
    <>
      <div>
        <header className="border-bottom fixed-top bg-white header-section">
          <div className="container">
            <div className="py-3">
              <img
                src="/assets/images/logo.png"
                className="img-fluid"
                width="150px"
                alt=""
              />
            </div>
          </div>
        </header>
        <section className="py-5">
          <div className="container">
            <div className="row justify-content-center main-section">
              {loading && <Loader />}
              {!loading && !isNotFound && !isComplete && (
                <>
                  <div className="col-lg-10">
                    <div className="card rounded-3">
                      <div className="p-4">
                        <div className="row g-3 align-items-center">
                          <div className="col-lg-7">
                            <div>
                              <h3 className="main-heading">
                                Just a few questions from our Pharmacist before
                                you checkout to make sure this medicine is safe
                                for you.
                              </h3>
                              <div className="d-flex align-items-center">
                                <div className="line" />
                                <div className="smdot" />
                                <div className="smdot" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 text-center justify-content-center">
                            <CircularProgressbar
                              value={
                                (answeredQuestions.length / questions.length) *
                                100
                              }
                              text={`${Math.round(
                                (answeredQuestions.length / questions.length) *
                                  100
                              )}%`}
                              strokeWidth={8}
                              styles={buildStyles({
                                textSize: "14px",
                                pathColor: `#4caf50`,
                                textColor: "#4caf50",
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-header justify-content-between d-flex align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src="/assets/images/chat/user.jpeg"
                            className="img-fluid rounded-3"
                            width={70}
                            style={{height:'45px'}}
                            alt="user-img"
                          />
                          <h6 className="m-0 fw-semibold">GPhC No. 2216145</h6>
                        </div>
                        <div>
                          <img
                            src="/assets/images/chat/registered-pharmacy1.png"
                            className="img-fluid"
                            style={{width:'80px'}}
                            alt="user-img"
                          />
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            {answeredQuestions.map((answeredQuestion) => (
                              <>
                                {editingQuestionId === answeredQuestion._id ? (
                                  renderQuestion(answeredQuestion._id)
                                ) : (
                                  <>
                                    <div className="col-lg-12">
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src="/assets/images/chat/user.jpeg"
                                          className="img-fluid rounded-3"
                                          width={70}
                                          style={{height:'45px'}}
                                          alt="user-img"
                                        />
                                        <div className="w-100">
                                          <label className="pb-1 fs-16 text-black m-3">
                                            {answeredQuestion.question}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="d-flex align-items-center gap-2 justify-content-end">
                                        <div className="right-block-box text-white fs-14">
                                          {answeredQuestion.selectedAnswer}
                                        </div>
                                        <div className="flex-shrink-0">
                                          <button
                                            type="button"
                                            className="border-0 bg-transparent btn-edit"
                                            title="Edit"
                                            style={{opacity:'.6',margin:'5px'}}
                                            onClick={() => setEditingQuestionId(answeredQuestion._id)}
                                          >
                                            <span data-bs-toggle="tooltip" data-bs-title="Edit">
                                              <img src="/assets/images/chat/dots.png" className="img-fluid" width="16px" alt="user-img"/>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                          {editingQuestionId === null && renderQuestion(getNextQuestionId())}
                        </div>
                        {getNextQuestionId() === null && Object.keys(warnings).length === 0 && (
                          <div className="card-footer text-center" style={{ marginTop: '10px' }}>
                            <>
                              <h6 className="text-center mb-3 mt-4" style={{ fontSize: '20px', fontFamily: 'serif' }}>
                                Thank you
                              </h6>
                              <p className="text-center mb-3">
                                Please click the Submit button to send us your answers.
                              </p>
                              <button
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={isSubmit}
                              >
                                {isSubmit && (
                                  <i className="fa fa-spinner fa-spin"></i>
                                )}{" "}
                                Submit
                              </button>
                            </>
                          </div>
                        )}

                      </div>

                    </div>
                  </div>
                </>
              )}

              {isNotFound && !isAssessmentCompleted && (
                <div class="col-12 text-center mb-5">
                  <div class="error-template">
                    <h1> Oops!</h1>
                    <h2>404 Not Found</h2>
                    <div class="error-details">
                      Sorry, an error has occured, Requested page not found!
                    </div>
                  </div>
                </div>
              )}
              {isNotFound && isAssessmentCompleted && (
                <div class="col-12 text-center mb-5">
                  <div className="card card-thanks" style={{backgroundColor:'aliceblue'}}>
                    <div className="card-body" >
                      <div class="error-template" style={{padding:'30px'}}>
                        <h4 className="font-theme">This assessment has been completed</h4>
                        <div class="error-details font-theme">
                          If you believe you still need to complete an assessment, please get in touch with us
                          <a href="https://www.ebay.co.uk/cnt/intermediatedFAQ?requested=pillpharm"> via eBay message</a>  
                        </div>
                        <a className="btn btn-outline-primary btn-sm mt-4 font-theme" href="https://www.ebay.co.uk/str/pillpharm">Retun to eBay</a>
                      </div>
                      </div>
                    </div>
                </div>
              )}


              {isComplete && (
                <div className="col-12 text-center p-5">
                  <div className="card card-thanks" style={{backgroundColor:'aliceblue'}}>
                    <div className="card-body">
                      <img src={`/assets/images/thank-you.png`} style={{width:'100px'}} alt="" />
                      <h6 className="success-title font-theme">Thank you for completing the assessment</h6>
                      <p className="m-0 p-0 success-dec font-theme">Our pharmacy team will review your answers.</p>
                      <p className="m-0 p-0 success-dec font-theme">If approved, you will receive confirmation of dispatch.</p>
                      <p className="m-0 p-0 success-dec font-theme">You may now close this tab.</p>
                      <a className="btn btn-outline-primary btn-sm mt-4 font-theme" href="https://www.ebay.co.uk/str/pillpharm">Retun to eBay</a>
                    </div>
                  </div>
              </div>
              )}

            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Questionnaire;
