import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";

const MeetingGrid = ({ meetings, showDelModal }) => {
    const MeetingMonth = ({ date }) => {
        const month = moment(date).format('MMM'); 
        return month;
    };

    const MeetingDay = (dateString) => {
        const date = new Date(dateString);
        const dayOfMonth = date.getUTCDate();
        const formattedDay = dayOfMonth.toString().padStart(2, '0');
        return formattedDay
    };
    const formatTime = (dateString) => {
        return moment(dateString).format('h:mm A'); // "h:mmA" gives "11:00AM"
    };

    const getStatusClass = (status) => {
    switch (status) {
        case 'Pending':
            return 'bg-warning';
        case 'Scheduled':
            return 'bg-info';
        case 'Completed':
            return 'bg-success';
        case 'Postponed':
            return 'bg-warning';
        case 'Draft':
            return 'bg-gray';
        case 'Cancelled':
            return 'bg-danger';
        default:
            return '';
    }
};


  return (
    <>
        {meetings.map((meeting, index) => (
            <div className="h-100 col-6 mb-8">
                <div className="d-flex">
                    <div className="calendar">
                        <span className="calendar-month">{MeetingMonth(meeting.date)}</span>
                        <span className="calendar-day">{MeetingDay(meeting.date)}</span>
                    </div>
                    <div className="flex-1 position-relative ps-3">
                        <h6 className="fs-9 mb-0">
                            <Link to={`/meetings/detail/${meeting._id}`} className="meeting-title">
                                <span className="me-1">{meeting.title}</span>
                            </Link>
                        </h6>
                        <p className="mb-1"><b className="bolder">Organised by</b><a className="text-700 ps-1" >{meeting.organiser}</a></p>
                        <p className=" mb-0">
                            <span className="bolder">Invitees:</span> {meeting.invitees && meeting.invitees.length > 0 ? 
                                    meeting.invitees.join(', ') : 'N/A'}
                        </p>
                        <p className="text-1000 mb-0">{formatTime(meeting.date)}</p>
                        <p className="text-1000 mb-0"><span className="bolder">Agenda:</span>  {meeting?.agenda}</p>

                        <p className=" mb-0">
                            <span className="bolder">Tags:</span> {meeting.tags && meeting.tags.length > 0 ? 
                                    meeting.tags.join(', ') : 'N/A'}
                        </p>
                        {meeting.status && <span className={`badge ${getStatusClass(meeting.status)}`}>{meeting.status}</span>}
                        <div className="border-dashed border-bottom my-3" />
                        <div className="col-12 text-end">
                        <Link to={`/meetings/edit/${meeting._id}`} className="m-4">
                            <i className="fa fa-pen btn-icon"></i>
                        </Link>
                        <Link  className="m-4" onClick={()=>(showDelModal(meeting._id))}>
                            <i className="fa fa-trash btn-icon"></i>
                        </Link>
                        </div>

                    </div>
                </div>
            </div>
        ))}

    </>
  );
};

export default MeetingGrid;
