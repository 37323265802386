import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";

const OrderEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [buyerInfo, setBuyerInfo] = useState({}); 
    const [order, setOrder] = useState({
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        country: '',
        zip: '',
        
    });

    const fetchOrder = async () => {
        try {
          setIsDataLoading(true);
          const res = await get(`api/orders/${id}`);
          if (res.success) {
            const  shippingInfo  = res.order.shippingInfo;
            setOrder({
                addressLine1: shippingInfo.addressLine1,
                addressLine2: shippingInfo.addressLine2,
                addressLine3: shippingInfo.addressLine3,
                city: shippingInfo.city,
                country: shippingInfo.country,
                zip: shippingInfo.zip
            });
            setBuyerInfo(res.order.buyerInfo);
          } else {
            setNotFound(true);
          }
        } catch (error) {
          setNotFound(true);
        } finally {
          setIsDataLoading(false);
        }
      };


    useEffect(() => {
        fetchOrder();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Update formData with the new value
        setOrder({
          ...order,
          [name]: value // Update the specific field with its new value
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const Body = {
                addressLine1: order.addressLine1,
                addressLine2: order.addressLine2,
                addressLine3: order.addressLine3,
                city: order.city,
                country: order.country,
                zip: order.zip
            };
            const res = await post(`api/orders/update/${id}`, Body);
            console.log(res)
            if (res.success) {
                toast.success(res.message);
                setIsLoading(false);
                navigate(`/orders/${id}`)

            } else {
                toast.error(res.error);
              setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };


  return (
    <>
    <Layout>
        <div>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-0 bd-heading">
                            Edit Order
                        </h2>
                        <Breadcrumbs />

                    </div>
                </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card user-card" style={{background:'white'}}>
                    <div className="card-body">
                        <p className="m-0 p-0 username-card" >{buyerInfo?.fullname} </p>
                        <p className="m-0 p-0 user-desc-card" style={{fontStyle:'normal'}}>
                            <i className="fa fa-phone" style={{fontSize:'10px'}}></i>  {buyerInfo?.phone} 
                        </p>
                        <p className="m-0 p-0 user-desc-card" style={{fontStyle:'normal'}}>
                        <i className="fa fa-envelope" style={{fontSize:'10px'}}></i>   {buyerInfo?.email} 
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-12"> 
                            <div className="card mb-8 rounded-4">
                                <div className="card-body p-7">
                                {isDataLoading &&
                                    <div className='text-center'>
                                        <Loader />
                                    </div>
                                }
                                {!isDataLoading && !isNotFound &&
                                    <form className="form-border-1" onSubmit={handleSubmit}>
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                Address Line 1                                            
                                            </label>
                                            <input type="text" name="addressLine1" placeholder="Type here" className="form-control" value={order.addressLine1} onChange={handleInputChange}  required />
                                        </div>
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                Address Line 2                                            
                                            </label>
                                            <input type="text" name="addressLine2" placeholder="Type here" className="form-control" value={order.addressLine2} onChange={handleInputChange}   />
                                        </div>
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                Address Line 3                                            
                                            </label>
                                            <input type="text" name="addressLine3" placeholder="Type here" className="form-control" value={order.addressLine3} onChange={handleInputChange}   />
                                        </div>
                                        <div className="row">
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                    City                                           
                                                </label>
                                                <input type="text" name="city" placeholder="Type here" className="form-control" value={order.city} onChange={handleInputChange}  required />
                                            </div>
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                    Country Code                                           
                                                </label>
                                                <input type="text" name="country" placeholder="Type here" className="form-control" value={order.country} onChange={handleInputChange}  required />
                                            </div>
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                    Postcode                                           
                                                </label>
                                                <input type="text" name="zip" placeholder="Type here" className="form-control" value={order.zip} onChange={handleInputChange}  required />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end">
                                            <button type="submit" className="btn btn-primary"  disabled={isLoading}>
                                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}   Update
                                            </button>
                                        </div>
                                    </form>
                                }
                                {!isDataLoading && isNotFound && 
                                    <div className="text-center">
                                    <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
                                    <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                    </div>
                                }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default OrderEdit