import React from 'react';


const Loader = () => {
  return (
    <>
        <span className="data-loader"></span>
    </>
  )
}

export default Loader