import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Layout from '../components/Layout';
import { get } from '../services/ApiService';
import "./custom-style.css";
import { Line } from 'react-chartjs-2';
import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false); 
  const [notStartedCount, setNotStartedCount] = useState(0); 
  const [totalPMedOrders, setTotalPMedOrders] = useState(0); 
  const [pendingPMedOrders, setPendingPMedOrders] = useState(0); 
  const [approvedPMedOrders, setapprovedPMedOrders] = useState(0); // Initialize as null initially
  const [productData, setProductData] = useState(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [productWithoutCategory, setProductWithoutCategory] = useState(0); 
  const [pmedWithoutQuestions, setPmedWithoutQuestions] = useState(0); 
  const [variationWithoutWeight, setVariationWithoutWeight] = useState(0); 



  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    try {
        const res = await get(`api/dashboard/stats`);
        console.log(res)
        if (res.success) {
          console.log(res.months)
            const formattedData = formatChartData(res.months);
            setChartData(formattedData);
            setNotStartedCount(res.notStartedCount);
            setTotalPMedOrders(res.totalPMedOrders);
            setPendingPMedOrders(res.pendingPMedOrders);
            setapprovedPMedOrders(res.approvedPMedOrders);
            setProductWithoutCategory(res.productWithoutCategory)
            setPmedWithoutQuestions(res.pmedWithoutQuestions)
            setVariationWithoutWeight(res.variationWithoutWeight)

            // Prepare options for product chart
            const productOptions = {
              animationEnabled: true,
              title: {
                text: 'GSL vs P-Med Products',
              },
              data: [
                {
                  type: 'pie',
                  startAngle: 240,
                  indexLabel: '{label} - {y}',
                  dataPoints: [
                    { label: 'GSL Products', y: res.gslProductsCount },
                    { label: 'P-Med Products', y: res.pmedProductsCount},
                  ],
                },
              ],
            };

            // Set product data options
            setProductData(productOptions);
    
        } 
    } catch (error) {
      console.log(error)
    }
  }

  const formatChartData = (data) => {
    const months = data.map(item => ({
      label: new Date(0, item.month - 1).toLocaleString('default', { month: 'short' }),
      gsl: item.GSL,
      pMed: item.PMed
    }));

    return {
      datasets: [
        {
          type: "line",
          name: "GSL Orders",
          showInLegend: true,
          dataPoints: months.map((month) => ({ label: month.label, y: month.gsl })),
          fillOpacity: .3,
          color: "rgba(54,158,173,.7)"
        },
        {
          type: "line",
          name: "P-Med Orders",
          showInLegend: true,
          dataPoints: months.map((month) => ({ label: month.label, y: month.pMed })),
          fillOpacity: .3,
          color: "rgba(210,59,123,.7)"
        }
      ]
    };
  };

  const options = {
    data: chartData.datasets
  };


  return (
    <>
    <Layout>
      <div className="row mb-9 align-items-center">
        <div className="col-sm-6 mb-8 mb-sm-0">
          <h2 className="fs-4 mb-0">Dashboard</h2>
          <p className="mb-0">
            Whole data about your business here
          </p>
        </div>
        <div className="col-sm-6 d-flex flex-wrap justify-content-sm-end">
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 col-xl-4 col-lg-4 col-md-4 mb-7">
          <div className="card rounded-4">
            <div className="card-body p-7">
              <div className="d-flex">
                <div className="media-body">
                  <h6 className="mb-4 card-title">
                    Product without category
                  </h6>
                  <span className="fs-4 d-block font-weight-500 text-primary lh-12">{productWithoutCategory}</span>
                  <span className="fs-14px">Count of product which don't have category.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4 col-lg-4 col-md-4 mb-7">
          <div className="card rounded-4">
            <div className="card-body p-7">
              <div className="d-flex">
                <div className="media-body">
                  <h6 className="mb-4 card-title">
                    P-Med without questions
                  </h6>
                  <span className="fs-4 d-block font-weight-500 text-primary lh-12">{pmedWithoutQuestions}</span>
                  <span className="fs-14px">P-Med Product which don't have questions </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4 col-lg-4 col-md-4 mb-7">
          <div className="card rounded-4">
            <div className="card-body p-7">
              <div className="d-flex">
                <div className="media-body">
                  <h6 className="mb-4 card-title">
                    Veriations without weight
                  </h6>
                  <span className="fs-4 d-block font-weight-500 text-primary lh-12">{variationWithoutWeight}</span>
                  <span className="fs-14px">Veriation count which don't have weight</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-4 col-lg-4 col-md-3 mb-7">
          <div className="card rounded-4">
            <div className="card-body p-7">
              <div className="d-flex">
                <div className="me-6">
                  <span className="square d-flex align-items-center justify-content-center fs-5 badge rounded-circle text-info bg-info-light" style={{squareSize: 48}}>
                    <i className="fas fa-shopping-bag" />
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mb-4 card-title">
                    Processed P Orders
                  </h6>
                  <span className="fs-4 d-block font-weight-500 text-primary lh-12">{approvedPMedOrders}</span>
                  <span className="fs-14px">Approved P orders awaiting dispatch count</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <h6 className=" mb-4"> Sales Statistics</h6>
              <div className="chart-container mt-4">
              <CanvasJSChart options={options} />
            </div>
          </div>
        </div>
        </div>
        <div className="col-lg-4">
        <div className="chart-container">
          {productData ? (
            <CanvasJSChart options={productData} />
          ) : (
            <p className="text-center">Loading product statistics...</p>
          )}
        </div>
        </div>
    </div>

    </Layout>
    </>
  )
}

export default Dashboard