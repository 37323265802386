import React from 'react'

const Footer = () => {
  return (
    <>
<footer className="pt-6 pb-10 footer-dashboard mt-auto">
  <div className="row">
    <div className="col-sm-6 text-sm-start text-center">
      © PillPharm &amp; Ebay Portal
    </div>
    <div className="col-sm-6 text-sm-end text-center">
      All rights reserved
    </div>
  </div>
</footer>


    </>
  )
}

export default Footer