import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, del } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams } from "react-router-dom";
import { Button, message, Popconfirm, Flex, Modal, Input} from 'antd';
import AddNoteModal from "../../components/AddNoteModal";

function truncateText(text, wordLimit) {
    if (typeof text !== 'string') return '';


    if (text.length > wordLimit) {
        return text.slice(0, wordLimit) + '...';
    }
    return text;
}
const OrderAssessment = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isChatLoading, setIsChatLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isNotFound, setNotFound] = useState(false); 
    const [assessment, setAssessment] = useState(''); 
    const [productsAssessment, setProductsAssessment] = useState([]); 
    const [mailLogs, setMailLogs] = useState([]); 
    const [productIds, setProductIds] = useState([]); 
    const [approvedPro, setApprovedPro] = useState([]); 
    const [cancelledPro, setCancelledPro] = useState([]); 
    const [cancelReasons, setCancelReasons] = useState({});
    const [isAllApproved, setIsAllApproved] = useState(false);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [order, setOrder] = useState('');
    const [groupedOrders, setGroupedOrders] = useState({});
    const allProductsProcessed = approvedPro.length + cancelledPro.length === productIds.length;
    const chatContainerRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cancelReasonInput, setCancelReasonInput] = useState('');
    const [cancelProductId, setCancelProductId] = useState(null);
    const [noteModalVisible, setNoteModalVisible] = useState(false);
    const [notes, setNotes] = useState([]);
    useEffect(() => {
         fetchData();
         getMessages(); 
    }, [id]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await get(`api/orders/p-med/assessment/${id}`);
            setIsLoading(false);
            console.log(res.productAssessment)
            if (res.success) {
                setOrder(res.order);
                setAssessment(res.assessment);
                setMailLogs(res.mailLogs);
                setNotes(res.notes)
                if(res.assessment && res.assessment.products){
                    const ids = res.assessment.products.map(product => product.productId);
                    setProductIds(ids);
                }

                const orders = res.orders;
                if(orders.length > 0 ){
                    const grouped = groupOrdersByMonth(orders);
                    setGroupedOrders(grouped);
                }

            } else {
                setOrder('');
                setAssessment('');
                setNotFound(true);
            }
        } catch (error) {
            
            setIsLoading(false);
            setOrder('');
            setAssessment('');
            setNotFound(true);
        } 
    };
    const getMessages = async () => {
        try {
            setIsChatLoading(true);
            const res = await get(`api/messages/${id}`);
            setIsChatLoading(false);
            if (res.success) {
                console.log(res.data)
                setMessages(res.data);
            } else {
                setNotFound(true);
            }
        } catch (error) {
            setIsChatLoading(false);
            console.log(error);
            setNotFound(true);

        } 
    };

    const getStatusDetails = (status) => {
        switch (status) {
          case "NOT_STARTED":
            return { text: "ERROR", class: "alert-danger" }; // RED
          case "PROCESSED":
            return { text: "SENT TO RM", class: "alert-primary" }; // BLUE
          case "ASSESSMENT_REQUIRED":
            return { text: "ASSESSMENT INCOMPLETE", class: "alert-secondary" }; // GREY
          case "PENDING":
            return { text: "PENDING P APPROVAL", class: "alert-warning" }; // ORANGE
          case "SHIPPED":
            return { text: "DISPATCHED", class: "alert-success" }; // GREEN
          default:
            return { text: status, class: "alert-secondary" }; // Default case
        }
    };

    const handleSubmit = async (e) => {
        try {
            const Body = {
                approved:approvedPro,
                canceled: cancelledPro,
                cancelReasons: cancelReasons,
                assessmentId:assessment._id,
                orderId:id
            };
            setIsSubmit(true);
            const res = await post(`api/orders/p-med/assessment/handling`, Body);
            setIsSubmit(false);
            if (res.success) {
                toast.success(res.message);
                setAssessment(res.assessment);
            } else {
                toast.error(`Failed to update assessment`);
            }
        } catch (error) {
            setIsSubmit(false);
            toast.error('An error occurred. Please try again.');
        }
    };
    
    const handleSendMessage = async () => {

        const Body = {
            message:message,
            orderId:id
        };
        setIsSending(true);
        const res = await post(`api/messages/send`, Body);
        setIsSending(false);
        if (res.success) {
            toast.success('Message sent');
            const newMessage = {
                Body: message,
                CreationDate: new Date().toISOString(),
                MessageID: "",
                Sender: "pillpharm",
                Subject: ""
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setMessage('');
        } 

    };

    const groupOrdersByMonth = (orders) => {
        const groupedOrders = {};
        orders.forEach(order => {
            const date = new Date(order.createdAt);
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            const monthYear = `${month} ${year}`;
        
            if (!groupedOrders[monthYear]) {
            groupedOrders[monthYear] = [];
            }
            groupedOrders[monthYear].push(order);
        });
        
        return groupedOrders;
    };
    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllApproved(isChecked);

        if (isChecked) {
            // Add all product IDs to approvedPro
            setApprovedPro(productIds);
        } else {
            // Remove all product IDs from approvedPro
            setApprovedPro([]);
        }
    };
    const showCancelModal = (id) => {
        setCancelProductId(id);
        setIsModalVisible(true);
    };
    const handleCancelOk = () => {
        if (cancelProductId && cancelReasonInput.trim()) {
            setCancelledPro([...cancelledPro, cancelProductId]);
            setCancelReasons({
                ...cancelReasons,
                [cancelProductId]: cancelReasonInput.trim()
            });
            setCancelReasonInput('');
            setCancelProductId(null);
            setIsModalVisible(false);
        } else {
            toast.error('Please provide a reason for cancellation.');
        }
    };
    const handleCancelCancel = () => {
        setCancelReasonInput('');
        setCancelProductId(null);
        setIsModalVisible(false);
    };

    const confirmCancel = (id) => {
        setCancelledPro([...cancelledPro, id]);
    };

    const confirmApprove = (id) => {
        setApprovedPro([...approvedPro, id]);
    };

    const cancel = (e) => {};

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    }

    const formatMailDate = (date) => {
        // Ensure date is a Date object
        const d = new Date(date);
    
        // Get individual date and time components
        const year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let seconds = d.getSeconds();
    
        // Add leading zeros if necessary
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
    
        // Return formatted date string
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };

    const formattedDate = (date) => {
        const now = new Date();
        const msgDate = new Date(date);
      
        const isToday = now.toDateString() === msgDate.toDateString();
        const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === msgDate.toDateString();
      
        const formatTime = (date) => {
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
          return `${formattedHours}:${formattedMinutes} ${ampm}`;
        };
      
        let formattedDateStr = formatTime(msgDate);
        if (isToday) {
          formattedDateStr += ', Today';
        } else if (isYesterday) {
          formattedDateStr += ', Yesterday';
        } else {
          formattedDateStr += `, ${msgDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
        }
      
        return formattedDateStr;
    };
      

    const handleCreateNote = async (values) => {
        try {
            const Body = {
                category:'Order',
                orderId: id,
                ...values 
            }
            const res = await post(`api/notes/add`, Body);

            if (res.success) {
                setNotes([res.note, ...notes]);
                toast.success(res.message);
                setNoteModalVisible(false)
            } else {
                toast.error(`Failed to update assessment`);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        }
    };
  return (
    <>
    <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6 mb-8 mb-sm-0">
                            <h2 className="fs-4 mb-0">
                                Order assessment
                            </h2>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {isLoading &&
                        <div className='text-center p-4'>
                            <Loader />
                        </div>
                    }
                    {!isLoading && !isNotFound &&
                    <>
                        <div className="col-12">
                            <div className="card user-card">
                                <div className="card-body">
                                    <p className="m-0 p-0 username-card">{order?.buyerInfo?.fullname} </p>
                                    <p className="m-0 p-0 user-desc-card">{order?.buyerInfo?.phone} </p>
                                    <p className="m-0 p-0 user-desc-card">{order?.buyerInfo?.email} </p>
                                    {order.type === "P-Med" && 
                                        <div className="col-12 mt-2 text-end">
                                            {mailLogs && mailLogs.length > 0 && 
                                                <>
                                                    {mailLogs.map((log,index)=>(
                                                    <p className="m-0 p-0 text-danger user-desc-card" key={index}>
                                                        {index + 1 } / 4 assessments - {log.mailSentAt ? formatMailDate(log.mailSentAt):''}
                                                    </p>
                                                    ))}
                                                </>
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-5 col-md-5 col-5 mb-9">
                                <div className="card mb-7 rounded-4 p-7">
                                    <div className="card-body p-0">
                                        <h5 className="card-title fs-6 mb-6">
                                        Recent Orders
                                        </h5>
                                        {Object.keys(groupedOrders).map(monthYear => (
                                            <>
                                            <h6 className="text-primary">{monthYear}</h6>
                                            <ul key={monthYear} className="verti-timeline list-unstyled fs-13px mx-4">
                                                {groupedOrders[monthYear].map(order => (
                                                    <>
                                                    <li  className="event-list d-flex align-items-center position-relative">
                                                        <div className="event-timeline-dot"></div>
                                                        <div className="d-flex flex-row align-items-center">
                                                        <div className="me-6">
                                                            <h6 className="fs-13px mb-0">
                                                                <span className="d-inline-block me-6 w-50px" style={{fontSize:'10px'}}>
                                                                    {formatDate(order.createdAt)}
                                                                </span>
                                                                {/* <i className="fa fa-arrow-right"></i> */}
                                                            </h6>
                                                        </div>
                                                        <p className="mb-0 fs-13px">
                                                            <span> <Link to={`/order/${order._id}`}>{order.orderId}</Link> </span><br/>
                                                            {order.products.map((pro,index)=>(
                                                                <>
                                                                    <span title={pro.title}> {truncateText(pro.title,25)} x{pro.quantity}</span><br/>
                                                                </>
                                                            ))}
                                                        </p>
                                                        </div>
                                                    </li>
                                                    
                                                    </>
                                                    
                                                ))}
                                            </ul>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                {assessment  && assessment.products.length > 0 && 
                                    <>
                                    {assessment && order.status !== "PROCESSED" && assessment.products.length > 1 &&
                                        <h6>
                                            <div className="form-check">
                                                    <input className="form-check-input rounded-0 ms-0" type="checkbox"  style={{borderColor:'#ee5454'}} checked={isAllApproved} onChange={handleCheckboxChange}/>
                                                    <label className="form-check-label m-3" style={{color:'#ee5454',fontWeight:200,fontFamily:'ui-monospace'}}> Do you want to approve whole order ?</label>
                                            </div>
                                        </h6>
                                    }

                                    {assessment.products.map((proAssess, index) => (
                                        
                                        <div className="accordion accordion-flush" id="accordionFlushExample" key={proAssess.productId}>
                                            <div className="accordion-item mb-4">
                                                <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`} aria-expanded="false" aria-controls={`flush-collapse-${index}`}>
                                                    {truncateText(proAssess.productName, 35)} 
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading-${index}`} data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        {proAssess.questions && proAssess.questions !== '' && 
                                                        proAssess.questions.map((res, key) => (
                                                            <div key={res.id}>
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                                        <div className="left-box">
                                                                            <p className="left-box-content" style={{fontSize:'12px'}}>
                                                                                {res.title}
                                                                                {res.description && 
                                                                                    <><br/><span style={{fontWeight:'100',color:'brown',fontSize:'11px'}} dangerouslySetInnerHTML={{ __html: res.description }} /></>
                                                                                }   

                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-12"></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-sm-12"></div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                                        <div className="right-box m-5">
                                                                            <p className="right-box-content" style={{fontSize:'12px',float:'right'}}>{res.response}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <hr/>
                                                        {assessment && (order.status === "PENDING" || order.status === "ASSESSMENT_REQUIRED") && !approvedPro.includes(proAssess.productId) && !cancelledPro.includes(proAssess.productId) &&
                                                            <>                                                          
                                                                <div className="col-12 text-end mt-2">
                                                                    <Flex wrap gap="small">
                                                                        {/* <Popconfirm
                                                                            title="Cancel the product"
                                                                            description="Are you sure to cancel this product?"
                                                                            onConfirm={() => confirmCancel(proAssess.productId)}
                                                                            onCancel={cancel}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <Button danger type="dashed">X Cancel</Button>
                                                                        </Popconfirm> */}
                                                                        <button 
                                                                            className={`btn btn-sm btn-danger`}
                                                                            disabled={approvedPro.includes(proAssess.productId) || cancelledPro.includes(proAssess.productId)}
                                                                            onClick={() => showCancelModal(proAssess.productId)}
                                                                        >
                                                                            {cancelledPro.includes(proAssess.productId) ? 'X Declined' : 'X Decline'}
                                                                        </button>
                                                                        <Popconfirm
                                                                            title="Approve the product"
                                                                            description="Are you sure to Approve this product?"
                                                                            onConfirm={() => confirmApprove(proAssess.productId)}
                                                                            onCancel={cancel}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <Button type="primary">✓ Approve</Button>
                                                                        </Popconfirm>
                                                                    </Flex>
                                                                </div>
                                                            </>
                                                        }
                                                        {approvedPro.includes(proAssess.productId) && <span className="text-primar">Product Approved</span>}
                                                        {cancelledPro.includes(proAssess.productId) && <span className="text-danger">Product Cancelled</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    ))}

                                    {assessment && (order.status === "PENDING" || order.status === "ASSESSMENT_REQUIRED") && allProductsProcessed && (
                                        <div className="text-center mt-4">
                                            <Button type="primary" onClick={handleSubmit} disabled={isSubmit}>
                                                {isSubmit && <i className="fa fa-spinner fa-spin"></i>}  Submit Approved/Cancelled Products
                                            </Button>
                                        </div>
                                    )}
                                    {assessment.products.map((product, index) => (
                                        <>
                                        {product.isCancel && 

                                            <div className="card note-card" style={{background:'antiquewhite'}}>
                                                <div className="card-body">
                                                    <p className="m-0 p-0 note-title text-danger">Product Cancelled</p>
                                                    <p className=" m-0 p-0 note-product">{truncateText(product.productName, 45)} </p>
                                                    <p className=" m-0 p-0 note-body">{product.reason} </p>
                                                    <div className="note-footer mt-4 mb-4">
                                                        <p className="m-0 p-0 note-date">  <i className="fa fa-clock"></i> {formatMailDate(assessment.mailSentAt)} </p>
                                                    </div>
                                                </div>
                                            </div>

                                        }

                                        </>
                                    ))}
                                    </>
                                }
                                <Button className="dotted-border mt-2" onClick={() => setNoteModalVisible(true)}>
                                    Add New Note
                                </Button>
                                {notes && notes.length > 0 && 
                                    notes.map((note,index)=>(
                                        <div className="card note-card mt-4" key={index}>
                                            <div className="card-body">
                                                <p className="m-0 p-0 note-title text-danger">{truncateText(note.title, 45)}</p>
                                                <p className=" m-0 p-0 note-body">{note.description} </p>
                                                <div className="note-footer mt-4 mb-4">
                                                    <p className="m-0 p-0 note-date">  <i className="fa fa-clock"></i> {formatMailDate(note.createdAt)} </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 col-7">
                            <div className="chat">
                                <div className="chat-header clearfix">
                                    <div className="row">
                                        <div className="col-6"><div className="chat-with">{order.orderId}</div></div>
                                        <div className="col-6 text-end">
                                            <span
                                                className={`badge rounded-lg rounded-pill alert py-3 px-4 mb-0 border-0 text-capitalize fs-12 ${getStatusDetails(order.status).class}`}
                                                >
                                                {getStatusDetails(order.status).text}
                                            </span>
                                        </div>
                                    </div>
                                </div> 
                                <div className="chat-history" ref={chatContainerRef}>
                                    {isChatLoading &&
                                        <div className='text-center' >
                                            <Loader />
                                        </div>
                                    } 
                                    <ul >
                                        {!isChatLoading && messages &&
                                            messages.map((msg, index) => (
                                            <>
                                            {msg.Sender === 'pillpharm' ? (
                                                <li>
                                                    <div className="message-data">
                                                        <span className="message-data-name"><i className="fa fa-circle online" /> Pillpharm</span>
                                                        <span className="message-data-time">{formattedDate(msg.CreationDate)}</span>
                                                    </div>
                                                    <div className="message my-message">
                                                    {msg.Body}
                                                    </div>
                                                </li>
                                            ):(
                                                <li className="clearfix">
                                                    <div className="message-data align-right">
                                                        <span className="message-data-time">{formattedDate(msg.CreationDate)}</span> &nbsp; &nbsp;
                                                        <span className="message-data-name">{msg.Sender}</span> <i className="fa fa-circle me" />
                                                    </div>
                                                    <div className="message other-message float-right">
                                                        {msg.Body}
                                                    </div>
                                                </li>
                                            )}
                                            </>
                                        ))}
                                    </ul>
                                </div>
                                <div className="chat-message clearfix">
                                    <textarea name="message-to-send" id="message-to-send" placeholder="Type your message" rows={3} onChange={(e) => setMessage(e.target.value)} value={message} />
                                    <button onClick={handleSendMessage} disabled={isSending}>
                                    {isSending && <i class="fa fa-spinner fa-spin"></i>}  Send
                                    </button>
                                </div> 
                            </div>
                            </div>
                        </div>
                    </>
                    }
                    {!isLoading && isNotFound && 
                        <div className="text-center">
                            <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
                            <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                        </div>
                    }
                </div>
            </div>
            <AddNoteModal
                visible={noteModalVisible}
                onCreate={handleCreateNote}
                onCancel={() => setNoteModalVisible(false)}
            />
            <Modal 
                title="Enter Cancellation Reason" 
                visible={isModalVisible} 
                onOk={handleCancelOk} 
                onCancel={handleCancelCancel}
                okText="Submit"
                cancelText="Cancel"
            >
                <Input.TextArea 
                    rows={4} 
                    value={cancelReasonInput}
                    onChange={(e) => setCancelReasonInput(e.target.value)}
                    placeholder="Enter the reason for cancellation"
                />
            </Modal>
    </Layout>
    </>
  )
}

export default OrderAssessment