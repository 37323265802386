import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import "../custom-style.css";
import Loader from "../../components/Loader";
import Breadcrumbs from "../../components/Breadcrumbs";

const DemoChat = () => {


  return (
    <>
    <Layout>
        <div>

            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-0 bd-heading">
                            chat
                        </h2>
                        <Breadcrumbs />

                    </div>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                    <div className="col-sm-12 col-lg-5 col-md-5 col-5"></div>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-7">


                    </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default DemoChat