
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const authed = localStorage.getItem('token');
    const storedToken = localStorage.getItem('token-expiration');
    const currentTime = Math.floor(Date.now() / 1000);

    if(authed && storedToken > currentTime){
        return children;
    }else{
        return <Navigate to="/login" replace />
    }
}

export default PrivateRoute;