import React, { useState, useEffect, useRef } from "react";
import Layout from '../../../components/Layout'
import { get, post } from '../../../services/ApiService';
import "./style.css";
import Loader from "../../../components/Loader";
import Pagination from '../../../components/Pagination';
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import FileUploadModal from "./FileUploadModal";
import DeleteModal from "../../../components/DeleteModal";
import { message } from "antd";

const FileList = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [isDelete, setIsDelete] = useState(false); 
    const [files, setFiles] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [delVisible, setDelVisible] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        fetchData()
    }, []);
    
    const fetchData = async () => {

        try {
            setIsLoading(true);

            const res = await get(`api/external-log/files`);
            if (res.success) {
              setIsLoading(false);
              setFiles(res.files);
              setNotFound(false);

            } else {
              setFiles([]);
              setIsLoading(false);
              setNotFound(true);
            }
        } catch (error) {
            setFiles([]);
            setTotalPages(1);
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showModal = () => {
        setModalVisible(true);
    };
    
    const closeModal = () => {
        setModalVisible(false);
    };

    const showDelModal = (id) => {
        setId(id)
        setDelVisible(true);
    };
    const closeDelModal = () => {
        setDelVisible(false);
    };

    const deleteFile = async () => {

        try {
            setIsDelete(true)
            const res = await get(`api/external-log/files/delete/${id}`);
            setIsDelete(false)
            if (res.success) {
                message.success('File deleted Successfully');
                closeDelModal();
                fetchData();
            } else {
                message.error('Failed to delete file')

            }
        } catch (error) {
            message.error('Failed to delete file')
        }
    };





  return (
    <>
        <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="col-sm-6 mb-8 mb-sm-0">
                    <h2 className="fs-4 mb-0">File Manager</h2>
                    <Breadcrumbs />
                </div>
                <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                    <Link  className="btn btn-primary btn-sm" onClick={showModal}>Add New</Link>
                </div>
            </div>
            <div className="card mb-4 rounded-4 p-7">
                <div className="card-body px-0 pt-7 pb-0">
                <div className="content">
                <div className="container">
                    <div className="row">
                    <div className="col-12">
                        <div className="card-box">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6">
                            <h4 className="header-title m-b-30">My Files</h4>
                            </div>
                        </div>
                        <div className="row">
                            {files && files.length > 0 && 
                            files.map((file,index)=>(
                                <div className="col-lg-3 col-xl-3" key={index}>
                                    <div className="file-man-box">
                                        <a href className="file-close" onClick={()=>(showDelModal(file._id))}>
                                            <i className="fa fa-times-circle" />
                                        </a>
                                        <div className="file-img-box">
                                            {file.type === 'PDF' && 
                                                <img src="/assets/images/pdf-icon.svg" alt="icon" />
                                            }
                                            {file.type === 'WORD' && 
                                                <img src="/assets/images/word-icon.png" alt="icon" />
                                            }
                                            {file.type === 'TXT' && 
                                                <img src="/assets/images/txt-icon.svg" alt="icon" />
                                            }
                                        </div>
                                        <a href className="file-download">
                                            <img src="/assets/images/download.png" alt="icon" style={{width:'20px',marginBottom:'10px'}} />
                                        </a>
                                        <div className="file-man-title">
                                            <h5 className="mb-0 text-overflow">{file.filename}</h5>
                                            {/* <p className="mb-0"><small>568.8 kb</small></p> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                            }

                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                </div>
            </div>
            {totalPages && totalPages > 1 && 
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            }
            <FileUploadModal visible={modalVisible} onClose={closeModal} fetchData={fetchData} />
            <DeleteModal visible={delVisible} onClose={closeDelModal} onConfirm={deleteFile} isDelete={isDelete}/>
        </Layout>
    </>
  )
}

export default FileList